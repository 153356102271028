import { take, put } from "redux-saga/effects";
import axios from "axios";
import { currentChecklistActionTypes } from "../actions/actionTypes";
import * as currentChecklistActions from "../actions/currentChecklistActions";
import * as checklistEditorActions from "../actions/checklistEditorActions";
import * as commonFunctions from "../../Utils/Helpers/common";
export function* saveCurrentChecklistSaga() {
    while (true) {
      
        const action = yield take(currentChecklistActionTypes.SAVE_CURRENT_CHECKLIST);
        const MasterData = action.payload;
        
        let config = commonFunctions.getHttpConfig();
        if (
            MasterData.id===0

        ) {
           let data={
        id:"", 
        Type: MasterData.type,
    WorkflowState: MasterData.workflowState,
    ChecklistName: MasterData.checklistName,
    ChecklistSection: MasterData.checklistSection,
    Status: MasterData.status,
    SectionOrder: MasterData.sectionOrder,
    ChecklistPurpose: null,
    isDeleted:false,
   }
           let apiUrl = `${process.env.API_URL}/CheckList/Add?tenantId=${MasterData.currentTenant}`;
            try {
                const res = yield axios.post(apiUrl, data, config);
                 let convertedData = res.data === "Empty" ? [] : res.data;
           
                yield put(
                    currentChecklistActions.saveCurrentChecklistSucceeded({
                 convertedData 
                          })
                );
                yield put(
                    checklistEditorActions.getChecklist(MasterData.currentTenant)
                );
            } catch (e) {
                yield put(
                    currentChecklistActions.saveCurrentChecklistFailed({
                        ...MasterData,
                        saveCurrentChecklistFailed: true,
                        errorMessage: e.response.data.Message
                            ? e.response.data.message
                            : Object.values(e.response.data.errors)[0][0],
                    })
                );
            }
        } else {
          
            let data = {
                id: MasterData.id,
                Type: MasterData.type,
                WorkflowState: MasterData.workflowState,
                ChecklistName: MasterData.checklistName,
                ChecklistSection: MasterData.checklistSection,
                Status: MasterData.status,
                id: MasterData.id,
                SectionOrder: MasterData.sectionOrder,
                ChecklistPurpose: null,
                 isDeleted:false,
            };
          
           let apiUrl = `${process.env.API_URL}/CheckList/Update?tenantId=${MasterData.currentTenant}`;
            try {
                const res = yield axios.put(apiUrl, data, config);
                 let convertedData = res.data === "Empty" ? [] : res.data;
                yield put(
                    currentChecklistActions.saveCurrentChecklistSucceeded({
                        convertedData
                    })
                );
                yield put(
                    checklistEditorActions.getChecklist(MasterData.currentTenant)
                );
            } catch (e) {
                yield put(
                    currentChecklistActions.saveCurrentChecklistFailed({
                        ...MasterData,
                        saveCurrentChecklistFailed: true,
                    })
                );
            }
        }
    }
}
