import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import A_Button from "../../../../../Atoms/Inputs/Buttons/A_Button";
import A_TextField from "../../../../../Atoms/Inputs/TextFields/A_TextField";
import A_Snackbar from "../../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_SimpleDialog from "../../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import { messages } from "../../../../../../Utils/common/config/config";
const useStyle = makeStyles((theme) => ({
    root: {
        overflow: "auto",
        "& .MuiFormControl-root": {
            margin: theme.spacing(1),
            width: "auto",
        },
        "& .textfieldoption": {
            margin: theme.spacing(1),
            width: "auto",
        },
    },
    Removebutton: {
        margin: "10px !important",
        marginTop: "10px",
        border: "1px solid red",
    },
    Addbutton: {
        margin: "10px !important",
        marginTop: "10px",
    },
    labeloption: {
        float: "right",
        marginTop: "22px !important",
    },
    OptionSetNameH6: {
        marginTop: "10px",
    },
}));

const AddNewOptionSets = (props) => {
    const classes = useStyle();
    const [isSaved, setIsSaved] = React.useState(false);
    const [optionSetProps, setOptionSetProps] = useState(
        props.optionSetArr ? props.optionSetArr : {}
    );
    const [optionSetName, setOptionSetName] = useState("");
    const [errorInOptionSet, setIsErrorInOptionSet] = useState({});
    const [optionDataRows, setOptionDataRows] = useState([]);
    const [dataOption, setDataOption] = useState("");
    const [dataValue, setDataValue] = useState("");
    const [errorInOption, setIsErrorInOption] = useState({});
    const [errorInValue, setIsErrorInValue] = useState({});
    const [isDialogueOpen, setIsDialogueOpen] = useState(false);

    useEffect(() => {
        setOptionSetProps(props.optionSetArr);
        setOptionSetName(props.optionSetArr.optionSetName);
        setOptionDataRows(props.optionSetArr.options);
    }, [props.optionSetArr]);

    // pop up message
    const handleSnackbarClose = () => {
        setIsSaved(false);
    };
    const optionSetNameHandler = (event) => {
        let { value } = event.target;
        setOptionSetName(value);
        setOptionSetProps({ ...optionSetProps, optionSetName: value });
        setIsErrorInOptionSet({});
    };
    const handleConfirmation = (opt) => {
        setIsDialogueOpen(opt);
    };
    const handleSaveFormHandler = () => {
        setIsDialogueOpen(false);
        if (optionSetName == "" || optionSetName.trim() == "") {
            setIsErrorInOptionSet({
                isError: true,
                errorMsg: "This field is required.",
            });
            return;
        }
        if (optionDataRows.length === 0) {
            setIsErrorInOption({
                isError: true,
                errorMsg: "This field is required.",
            });
            setIsErrorInValue({
                isError: true,
                errorMsg: "This field is required.",
            });
            return;
        }
        let optionSetObject = {};
        optionSetObject.optionSetName = optionSetName;
        optionSetObject.id = parseInt(optionSetProps.id);
        optionSetObject.options = optionDataRows.map((element) => {
            element.isAdd = undefined;
            return element;
        });
        props.updateRecord(optionSetObject);
    };

    const setDataOptionHandler = (event) => {
        let { value } = event.target;
        setDataOption(value);
        setIsErrorInOption({});
    };
    const setDataValueHandler = (event) => {
        let { value } = event.target;
        setDataValue(value);
        setIsErrorInValue({});
    };

    const handleAddRowHandler = () => {
        // Validation  of fields
        if (
            dataOption == "" ||
            dataOption.trim() == "" ||
            dataValue == "" ||
            dataValue.trim() == ""
        ) {
            if (dataOption == "" || dataOption.trim() == "") {
                setIsErrorInOption({
                    isError: true,
                    errorMsg: "This field is required.",
                });
            }
            if (dataValue == "" || dataValue.trim() == "") {
                setIsErrorInValue({
                    isError: true,
                    errorMsg: "This field is required.",
                });
            }
            return;
        }
        let arr = [
            ...optionDataRows,
            {
                label: dataOption,
                value: dataValue,
                id: optionDataRows.length + 1,
                isAdd: true,
            },
        ];
        setOptionDataRows(arr);
        setDataOption("");
        setDataValue("");
        document.getElementById("inputOption").value = "";
        document.getElementById("inputValue").value = "";
    };
    const handleDeleteRowHandler = (data) => {
        let id = data.id;
        let arr = optionDataRows.filter(
            (item) => item.id.toString() !== id.toString()
        );
        setOptionDataRows([...arr]);
    };
    const handleOnUpdateOption = (e, data) => {
        optionDataRows.filter((item) => {
            item.id === data.id && (item.label = e.target.value);
        });
    };
    const handleOnUpdateValue = (e, data) => {
        optionDataRows.filter((item) => {
            item.id === data.id && (item.value = e.target.value);
        });
    };
    return (
        <>
            <Grid
                container
                alignItems="flex-start"
                spacing={0}
                className={classes.root}
            >
                <Grid container>
                    <OptionSetNameComponent
                        optionSetProps={optionSetProps}
                        optionSetNameHandler={optionSetNameHandler}
                        optionSetName={optionSetName}
                        handleBack={props.handleBack}
                        handleSaveFormHandler={handleConfirmation}
                        errorInOptionSet={errorInOptionSet}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OptionSetComponent
                        optionDataRows={optionDataRows}
                        isEdit={optionSetProps.options.length > 0}
                        handleDeleteRowHandler={handleDeleteRowHandler}
                        handleOnUpdateOption={handleOnUpdateOption}
                        handleOnUpdateValue={handleOnUpdateValue}
                    />

                    <AddOptionSetComponent
                        setDataOptionHandler={setDataOptionHandler}
                        setDataValueHandler={setDataValueHandler}
                        errorInOption={errorInOption}
                        errorInValue={errorInValue}
                        dataOption={dataOption}
                        dataValue={dataValue}
                        handleAddRowHandler={handleAddRowHandler}
                    />
                </Grid>
            </Grid>
            <A_Snackbar
                open={isSaved}
                message="Changes saved successfully"
                autoHideDuration={5000}
                horizontal="center"
                handleClose={handleSnackbarClose}
            />
            <A_SimpleDialog
                open={isDialogueOpen}
                title={messages.CONFIRMATION_MSG}
                dialogContent={""}
                onClose={() => {
                    handleConfirmation(false);
                }}
                dialogActions={
                    <div>
                        <A_Button
                            label="OK"
                            color="primary"
                            onClick={() => {
                                handleSaveFormHandler(isDialogueOpen);
                            }}
                        />{" "}
                        <A_Button
                            label="Back"
                            onClick={() => {
                                handleConfirmation(false);
                            }}
                        />
                    </div>
                }
            />
        </>
    );
};
export default AddNewOptionSets;

//  Add others component
const OptionSetNameComponent = (props) => {
    const classes = useStyle();
    const {
        optionSetProps,
        optionSetNameHandler,
        handleBack,
        handleSaveFormHandler,
        errorInOptionSet,
    } = props;

    return (
        <>
            <Grid item xs={8}>
                <Grid container>
                    <Grid item xs={4}>
                        <Typography
                            className={classes.OptionSetNameH6}
                            variant="h6"
                        >
                            OptionSet Name{" "}
                            <span className="requiredMark">*</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <A_TextField
                            className={classes.textField}
                            label=""
                            variant="outlined"
                            error={errorInOptionSet.isError}
                            helperText={errorInOptionSet.errorMsg}
                            defaultValue={optionSetProps.optionSetName}
                            onChange={optionSetNameHandler}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <>
                    <A_Button
                        label={
                            optionSetProps.options.length > 0
                                ? "Update Optionset"
                                : "Add OptionSet"
                        }
                        color="primary"
                        onClick={handleSaveFormHandler}
                    />
                    &nbsp;&nbsp;
                    <A_Button
                        label="Back"
                        color="default"
                        onClick={handleBack}
                    />
                </>
            </Grid>
        </>
    );
};

const AddOptionSetComponent = (props) => {
    const classes = useStyle();
    const {
        setDataOptionHandler,
        setDataValueHandler,
        errorInOption,
        errorInValue,
        dataOption,
        dataValue,
        handleAddRowHandler,
    } = props;
    return (
        <>
            <Grid container>
                <Grid item xs={2}>
                    <Typography edge="end" className={classes.labeloption}>
                        Options <span className="requiredMark">*</span>
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <A_TextField
                        className="textfieldoption"
                        variant="outlined"
                        id="inputOption"
                        label=""
                        error={errorInOption.isError}
                        helperText={errorInOption.errorMsg}
                        value={dataOption}
                        onChange={setDataOptionHandler}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Typography edge="end" className={classes.labeloption}>
                        Values <span className="requiredMark">*</span>
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <A_TextField
                        className="textfieldoption"
                        variant="outlined"
                        id="inputValue"
                        label=""
                        error={errorInValue.isError}
                        helperText={errorInValue.errorMsg}
                        value={dataValue}
                        onChange={setDataValueHandler}
                    />
                </Grid>
                <Grid item xs={2}>
                    <A_Button
                        style={{ margin: "10px !important" }}
                        className={classes.Addbutton}
                        label="Add"
                        color="primary"
                        onClick={handleAddRowHandler}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const OptionSetComponent = (props) => {
    const classes = useStyle();
    const {
        optionDataRows,
        handleDeleteRowHandler,
        handleOnUpdateOption,
        handleOnUpdateValue,
        isEdit,
    } = props;
    return (
        <>
            {optionDataRows.map((list) => (
                <>
                    <Grid container key={list.id}>
                        <Grid item xs={2}>
                            <Typography
                                edge="end"
                                className={classes.labeloption}
                            >
                                Options <span className="requiredMark">*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {isEdit && list.isAdd === undefined ? (
                                <Typography
                                    edge="end"
                                    className={classes.labeloption}
                                >
                                    {list.label}
                                </Typography>
                            ) : (
                                <A_TextField
                                    className="textfieldoption"
                                    label=""
                                    defaultValue={list.label}
                                    onChange={(e) =>
                                        handleOnUpdateOption(e, list)
                                    }
                                    variant="outlined"
                                />
                            )}
                        </Grid>
                        <Grid item xs={2}>
                            <Typography
                                edge="end"
                                className={classes.labeloption}
                            >
                                Values <span className="requiredMark">*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {isEdit && list.isAdd === undefined ? (
                                <Typography
                                    edge="end"
                                    className={classes.labeloption}
                                >
                                    {list.value}
                                </Typography>
                            ) : (
                                <A_TextField
                                    className="textfieldoption"
                                    label=""
                                    defaultValue={list.value}
                                    onChange={(e) =>
                                        handleOnUpdateValue(e, list)
                                    }
                                    variant="outlined"
                                />
                            )}
                        </Grid>
                        <Grid item xs={2}>
                            {!isEdit || list.isAdd ? (
                                <A_Button
                                    style={{
                                        margin: "10px !important",
                                    }}
                                    className={classes.Removebutton}
                                    label="Remove"
                                    color="secondary"
                                    onClick={(e) =>
                                        handleDeleteRowHandler(list)
                                    }
                                />
                            ) : (
                                ""
                            )}
                        </Grid>
                    </Grid>
                </>
            ))}
        </>
    );
};
