import { take, put } from "redux-saga/effects";
import axios from "axios";
import * as commonFunctions from "../../Utils/Helpers/common";
import { checklistItemActionTypes } from "../actions/actionTypes";
import * as checklistItemActions from "../actions/checklistItemActions";
export function* getChecklistItemSaga() {
    while (true) {
        const action = yield take(checklistItemActionTypes.GET_CHECKLIST_ITEM);
        const payload = action.payload;
        let config = commonFunctions.getHttpConfig();
        try {
            const res = yield axios.get(
                `${process.env.API_URL}/CheckListItem/GetSingleChecklistMasterItems?tenantId=${payload.tenantId}&CheckListSectionMasterId=${payload.masterId}`,
                config
            );
            let convertedData = res.data === "Empty" ? [] : res.data;
            yield put(
                checklistItemActions.getChecklistItemSucceeded({
                    currentTenant: payload,
                    data: convertedData,
                    getChecklistItemStarted: false,
                })
            );
        } catch (e) {
            checklistItemActions.getChecklistItemFailed({
                msg: "Some error occured",
                getChecklistItemStarted: false,
            });
        }
    }
}
