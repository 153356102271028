//checks for given value is empty or not.
export const isRequired = (value) => {
    let result = true;
    if (
        value === undefined ||
        value === null ||
        (value !== undefined &&
            value !== null &&
            typeof value === "string" &&
            value.trim().length === 0)
    ) {
        result = false;
    }
    return result;
};

//checks for given value exists in given array source or not.
export const isExists = (value, compareWith, source) => {
    let result = false;
    if (!isRequired(source) || !isRequired(value) || !isRequired(compareWith)) {
        return result;
    } else {
        if (source.length > 0) {
            let filteredItem = source.filter((item) => {
                return (
                    item[compareWith] !== null &&
                    item[compareWith] !== undefined &&
                    item[compareWith].toLowerCase() ===
                        value.toLowerCase().trim()
                );
            });
            result = filteredItem.length === 0 ? false : true;
        }
    }

    return result;
};
//checks for given value exists in given array source or not more than one time.
export const isExistsMoreThan1Time = (value, compareWith, source) => {
    let result = false;
    if (!isRequired(source) || !isRequired(value) || !isRequired(compareWith)) {
        return result;
    } else {
        if (source.length > 0) {
            let filteredItem = source.filter((item) => {
                return (
                    item[compareWith] !== null &&
                    item[compareWith] !== undefined &&
                    item[compareWith].toLowerCase() ===
                        value.toLowerCase().trim()
                );
            });
            result = filteredItem.length >= 2 ? true : false;
        }
    }

    return result;
};
//check if given value is a valid json object or not.
export const isValidJSON = (value, allowEmpty) => {
    try {
        if (!allowEmpty && !isRequired(value)) {
            return false;
        } else if (allowEmpty && !isRequired(value)) {
            return true;
        } else {
            JSON.parse(value);
        }
    } catch (e) {
        return false;
    }
    return true;
};

export const IsValidUrl = (userInput) => {
    var tempURL = userInput;
    var res = userInput.match(
        /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
    );
    if (res !== null || tempURL == "") return true;
    else return false;
};
export const IsValidPageUrl = (userInput) => {
    var tempURL = userInput;
    var res = userInput.match(/^[a-z,]{1,20}$/i);
    if (res !== null || tempURL == "") return true;
    else return false;
};
