import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import OptionSetsDetails from "../../../Pages/PageEditor/Editor/OptionSets/OptionsetMainPage/optionSetDetails";
import Toolbar from "../../../Pages/PageEditor/Editor/Toolbar/Toolbar";
import FieldsDisplay from "../../../Pages/PageEditor/Editor/Fields/MainPage/FieldsDisplay";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as currentPageActions from "../../../../../store/actions/currentPageActions";
import A_AppBar from "../../../Atoms/Surfaces/Appbars/A_AppBar";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import A_CircularIndeternment from "../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import {
    isRequired,
    isExists,
    isValidJSON,
    isExistsMoreThan1Time,
    IsValidPageUrl,
} from "../../../../../Utils/Helpers/validations";
import GeneralDetails from "../../../Pages/PageEditor/Editor/General/GeneralDetails";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <p>{children}</p>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    tabPanel: {
        width: "1000px",
        height: "600px",
    },
    tab: {
        width: "1000px",
    },
    ButtonPosition: {
        marginLeft: "7px",
        marginTop: "-125px",
    },
}));

const M_SideBar = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [value, setValue] = useState(0);
    const [saving, setSaving] = React.useState(false);
    const [showField, setShowField] = useState(true);
    const [currentPage, setCurrentPage] = useState({});
    const [generalDetails, setGeneralDetails] = useState(props.currentPage);
    const [toolbarValue, setToolbarValue] = useState(undefined);
    const [validationSummary, setValidationSummary] = useState({
        name: {},
        url: {},
    });
    const [changesSaved, setChangesSaved] = useState({
        status: false,
        type: "success",
        msg: "Changes saved successfully",
    });
    const [openDialog, setOpenDialog] = useState({ status: false, msg: "" });
    const [isAdd, setIsAdd] = useState(props.id === null ? true : false);
    const [showSaveButton, setshowSaveButton] = useState(true);

    useEffect(() => {
        setCurrentPage(props.currentPage);
        return () => {
            setCurrentPage({});
        };
    }, [currentPage]);

    useEffect(() => {
        setCurrentPage(props.currentPage);
        return () => {
            setCurrentPage({});
        };
    }, [props.currentPage]);

    const handleCrmTypeChange = (crmType) => {
        setShowField(crmType);
    };
    const handleChange = (event, newValue) => {
        if (handleValidations()) {
            setValue(newValue);
            setshowSaveButton(true);
        }
    };
    const handleGeneralDetails = (generalDetails) => {
        setGeneralDetails(generalDetails);
    };
    const handleToolbarValue = (toolbarValue) => {
        setToolbarValue(toolbarValue);
    };
    const handleValidations = () => {
        let validations = { name: {}, toolbarValue: {}, url: {} };

        let isValidated = true;
        let url =
            generalDetails.url === undefined
                ? props.currentPage.url
                : generalDetails.url;
        let name =
            generalDetails.name === undefined
                ? props.currentPage.name
                : generalDetails.name;
        if (!isRequired(name)) {
            isValidated = false;
            validations.name = { error: true, msg: "Name is required" };
        }
        if (!isRequired(url)) {
            isValidated = false;
            validations.url = { error: true, msg: "Url is required" };
        }
        //check for duplicate field name

        if (isAdd) {
            let isDuplicate = isExists(
                generalDetails.name,
                "name",
                props.pages.data
            );
            if (isDuplicate) {
                isValidated = false;
                validations.name = {
                    error: true,
                    msg: "Name is already exists.",
                };
            }
            let isDuplicateUrl = isExists(
                generalDetails.url,
                "url",
                props.pages.data
            );
            if (isDuplicateUrl) {
                isValidated = false;
                validations.url = {
                    error: true,
                    msg: "Url is already exists.",
                };
            }
        } else {
            let isDuplicate = isExistsMoreThan1Time(
                generalDetails.name,
                "name",
                props.pages.data
            );
            if (isDuplicate) {
                isValidated = false;
                validations.name = {
                    error: true,
                    msg: "Name is already exists.",
                };
            }
            let isDuplicateUrl = isExistsMoreThan1Time(
                generalDetails.url,
                "url",
                props.pages.data
            );
            if (isDuplicateUrl) {
                isValidated = false;
                validations.url = {
                    error: true,
                    msg: "Url is already exists.",
                };
            }
        }

        if (!IsValidPageUrl(url, true)) {
            isValidated = false;
            validations.url = {
                error: true,
                msg: "Url not in the required format(a-z, max lenght:20)",
            };
        }

        //validate toolbar json
        if (!isValidJSON(toolbarValue, true)) {
            isValidated = false;
            setOpenDialog({ status: true, msg: "Toolbar json is invalid." });
        }
        setValidationSummary(validations);
        return isValidated;
    };
    const handleSave = () => {
        //console.log('handle save is called:',props.currentPage);

        if (handleValidations()) {
            let pageData = props.currentPage;

            pageData.pageSecurity =
                "pageSecurity" in generalDetails
                    ? generalDetails.pageSecurity
                    : pageData.pageSecurity;
            pageData.accessGroup = []; //generalDetails.accessGroup;
            pageData.crmType =
                "crmType" in generalDetails
                    ? generalDetails.crmType
                    : pageData.crmType;
            pageData.description =
                "description" in generalDetails
                    ? generalDetails.description
                    : pageData.description;
            pageData.name =
                "name" in generalDetails ? generalDetails.name : pageData.name;
            pageData.type =
                "type" in generalDetails ? generalDetails.type : pageData.type;
            pageData.url =
                "url" in generalDetails ? generalDetails.url : pageData.url;
            pageData.defaultImage =
                "defaultImage" in generalDetails ? generalDetails.defaultImage : pageData.defaultImage;
            if (toolbarValue !== undefined) {
                pageData.toolbar = toolbarValue;
            }

            setSaving(true);
            props.saveCurrentPage({
                tenantId: props.tenantId,
                currentPage: pageData,
            });
            // setChangesSaved({status: true, msg: "Changes saved successfully"});
            props.onSave(true);
        }
    };

    // handle error response Message
    React.useEffect(() => {
        if (props.currentPage.errorMessage && saving) {
            // loading ends
            setSaving(false);
            // saved  message
            setChangesSaved({
                status: true,
                type: "error",
                msg: props.currentPage.errorMessage,
            });
        }
    }, [props.currentPage && props.currentPage.errorMessage]);

    // handle save response Message
    React.useEffect(() => {
        if (props.currentPage.currentPageSaved && saving) {
            // saved  message
            setChangesSaved({
                status: true,
                type: "success",
                msg: "Changes saved successfully",
            });
            // loading ends
            setSaving(false);
        }
    }, [props.currentPage && props.currentPage.currentPageSaved]);

    const handleBack = () => {
        history.push("/pageeditor");
    };
    const handleSnackbarClose = () => {
        setChangesSaved({ status: false, msg: "" });
    };
    const handleSimpleDialogClose = (value) => {
        setOpenDialog({ status: false, msg: "" });
    };
    const handleShowSaveButton = (flag) => {
        setshowSaveButton(flag);
    };

    return (
        <div className={classes.root}>
            <A_AppBar
                content={
                    <Tabs
                        className={classes.tab}
                        value={value}
                        onChange={handleChange}
                        aria-label="simple tabs example"
                    >
                        <Tab label="General" {...a11yProps(0)} />
                        <Tab
                            label="Fields"
                            {...a11yProps(1)}
                            disabled={!showField}
                        />
                        <Tab label="Option Sets" {...a11yProps(2)} />
                        <Tab label="Toolbar" {...a11yProps(3)} />
                    </Tabs>
                }
            />
            <TabPanel value={value} index={0} className={classes.tabPanel}>
                <GeneralDetails
                    tenantId={props.tenantId}
                    id={props.id}
                    onPageTypeChange={handleCrmTypeChange}
                    getFormData={handleGeneralDetails}
                    validationSummary={validationSummary}
                />
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabPanel}>
                <FieldsDisplay
                    currentPage={currentPage}
                    tenantId={props.tenantId}
                    showSaveButton={handleShowSaveButton}
                />
            </TabPanel>
            <TabPanel value={value} index={2} className={classes.tabPanel}>
                <OptionSetsDetails
                    tenantId={props.tenantId}
                    pageconfig={currentPage}
                    showSaveButton={handleShowSaveButton}
                />
            </TabPanel>
            <TabPanel value={value} index={3} className={classes.tabPanel}>
                <Toolbar
                    configs={currentPage.toolbar}
                    updatedToolbarValue={toolbarValue}
                    getToolbarValue={handleToolbarValue}
                />
            </TabPanel>
            &nbsp;&nbsp;
            {showSaveButton && (
                <A_Button
                    className={classes.ButtonPosition}
                    color="primary"
                    size="medium"
                    label="Save"
                    disabled={saving}
                    endIcon={
                        saving ? (
                            <A_CircularIndeternment
                                color="secondary"
                                size={20}
                            />
                        ) : (
                            React.createElement("span")
                        )
                    }
                    onClick={handleSave}
                />
            )}
            <A_Snackbar
                open={changesSaved.status}
                message={changesSaved.msg}
                type={changesSaved.type}
                autoHideDuration={5000}
                horizontal="center"
                handleClose={handleSnackbarClose}
            />
            <A_SimpleDialog
                style={{ margin: "auto" }}
                open={openDialog.status}
                title="Error"
                dialogContent={openDialog.msg}
                dialogActions={
                    <A_Button
                        onClick={handleSimpleDialogClose}
                        label="close"
                        color="secondary"
                        size="small"
                    />
                }
            ></A_SimpleDialog>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        pages: state.pages,
        currentPage: state.currentPage,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(currentPageActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(M_SideBar);
