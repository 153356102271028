import React, { useState, useEffect } from "react";
import A_EnhancedTable from "../../../Atoms/DataDisplay/DataGrid/A_EnhancedTable";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../../PageEditor/MainPage/Header/Header";
import * as tenantsActions from "../../../../../store/actions/tenantsActions";
import { useHistory } from "react-router-dom";
import * as chartEditorActions from "../../../../../store/actions/chartEditorActions";
import * as currentChartActions from "../../../../../store/actions/currentChartActions";
import * as chartPreviewActions from "../../../../../store/actions/chartPreviewActions";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";

let colmunConfiguration = {
    title: "",
    noDataText: "No data available.",
    paginate: true,
    searchable: true,
    sortable: false,
    striped: true,
    addButton: true,
    action: {
        edit: true,
        delete: true,
    },
    columnConfig: {
        name: {
            id: "name",
            label: "Name",
            sorting: false,
            hide: false,
        },
        description: {
            id: "description",
            label: "Description",
            sorting: false,
            hide: false,
        },
        type: {
            id: "type",
            label: "Type",
            sorting: false,
            hide: false,
        },
    },
};

const ChartEditor = (props) => {
    const defaultFormData = {
        chartId: null,
        id: null,
        chartName: "",
        description: "",
        type: "graph",
        javaScript: "",
        configuration: "{}",
        query: {
            source: "",
            spName: "",
            apiName: "",
            parameters: [],
        },
        resultParameters: "",
    };
    const history = useHistory();
    const [currentTenant, setCurrentTenant] = React.useState(
        props.chartEditor.currentTenant ? props.chartEditor.currentTenant : ""
    );
    const [currentTenantName, setCurrentTenantName] = React.useState("");
    const [currentChart, setCurrentChart] = React.useState({});
    const [chartPreview, setChartPreview] = React.useState({});
    const [changesSaved, setChangesSaved] = useState({
        status: false,
        msg: "Chart deleted successfully",
    });
    const [deletePopup, setDeletePopup] = React.useState(false);
    const [currenteleId, setCurrenteleId] = React.useState("");

    let chartEditorData =
        props.chartEditor &&
        props.chartEditor.data &&
        props.chartEditor.data != "Empty"
            ? props.chartEditor.data
            : [];

    let diaplayFilteredChartsData = chartEditorData.map((item) => {
        return {
            name: item.chartName,
            description: item.description,
            type: item.type,
            id: item.id,
        };
    });

    const tenantChangeHandler = async (e) => {
        let param = e;
        let currTenant = props.tenants.filter((tenant) => {
            return tenant.tenantId == param;
        })[0];
        setCurrentTenantName(currTenant.tenantName);
        props.getCharts(e);
        setCurrentTenant(e);
    };

    React.useEffect(() => {
        props.getTenants();
        if (props.chartEditor && props.chartEditor.currentTenant) {
            props.getCharts(props.chartEditor.currentTenant);
        }
    }, []);

    const handleAddButtonClick = (e) => {
        let path = "/ChartConfigurationForm/" + 0;
        props.setCurrentChart(defaultFormData);
        props.setChartPreview(defaultFormData);
        history.push({ pathname: path });
    };
    const handleEditButtonClick = (e) => {
        let arr = props.chartEditor.data.filter(
            (item) => e.currentTarget.id == item.id
        );
        let id = arr[0].id;
        let path = "/ChartConfigurationForm/" + id;
        props.setCurrentChart(arr[0]);
        props.setChartPreview(arr[0]);
        history.push({ pathname: path });
    };

    const handleSnackbarClose = () => {
        setChangesSaved({ status: false, msg: "" });
    };

    const handleConfrimDelete = () => {
        setChangesSaved({ status: true, msg: "Chart deleted successfully" });
        props.deleteCharts({
            id: currenteleId,
            tenantId: props.chartEditor.currentTenant,
        });
        setDeletePopup(false);
    };
    const handleCancelDelete = () => {
        setDeletePopup(false);
    };

    const handleDeleteButtonClick = (e) => {
        let id = e.currentTarget.getAttribute("dataid");

        let arr = props.chartEditor.data.filter((item) => id == item.id);

        let chartId = arr[0].id;
        setCurrenteleId(chartId);
        setDeletePopup(true);
    };

    const getActionItems = () => {
        return (
            <>
                <A_Button
                    color="primary"
                    label={"Ok"}
                    autoFocus
                    onClick={handleConfrimDelete}
                />

                <A_Button
                    color="default"
                    label={"Cancel"}
                    onClick={handleCancelDelete}
                />
            </>
        );
    };
    return (
        <>
            <Header
                id="toolbar"
                title="Charts editor"
                TenantData={props.tenants}
                value={currentTenant}
                isdisabledFlag={false}
                tenantChangeHandlerCallback={(e, name) =>
                    tenantChangeHandler(e, name)
                }
            />

            {currentTenant &&
            diaplayFilteredChartsData &&
            diaplayFilteredChartsData.length > 0 &&
            !props.fetchChartsFailed > 0 ? (
                <A_EnhancedTable
                    data={diaplayFilteredChartsData}
                    configurations={colmunConfiguration}
                    handleAddButtonClick={handleAddButtonClick}
                    handleEditButtonClick={handleEditButtonClick}
                    handleDeleteButtonClick={handleDeleteButtonClick}
                />
            ) : (
                ""
            )}
            <A_SimpleDialog
                id="2"
                open={deletePopup}
                dialogContent={"Are you sure you want to delete this chart ?"}
                dialogActions={getActionItems()}
            />
            <A_Snackbar
                open={changesSaved.status}
                message={changesSaved.msg}
                autoHideDuration={5000}
                horizontal="center"
                handleClose={handleSnackbarClose}
            />
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        tenants: state.tenants.data,
        chartEditor: state.chartEditor,
        currentChart: state.currentChart,
        chartPreview: state.chartPreview,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign(
            {},
            chartEditorActions,
            tenantsActions,
            currentChartActions,
            chartPreviewActions
        ),
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartEditor);
