import React from "react";
import {
    BrowserRouter as Router,
    Route,
    IndexRoute,
    Switch,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { store } from "../store";
import { syncHistoryWithStore } from "react-router-redux";
import M_Playground from "../components/Factory/Molecules/Common/M_Playground";
import MainPage from "../Components/Factory/Pages/Common/mainPage";
import CallbackPage from "../Components/Utils/Oidc/Callback";
import LoginPage from "../Components/Factory/Pages/Common/loginPage";
import PageEditor from "../components/Factory/Pages/PageEditor/MainPage/PageEditor";
import EditorMainPage from "../components/Factory/Pages/PageEditor/Editor/EditorMainPage/EditorMainPage";
import ChartEditor from "../components/Factory/Pages/ChartEditor/MainPage/ChartEditor";
import ChartMainPage from "../components/Factory/Pages/ChartEditor/Editor/Header/Header";
import EmailEditor from "../components/Factory/Pages/EmailEditor/EmailEditor";
import ShinyDashboard from "../components/Factory/Pages/ShinyDashboard/ShinyDashboard";
import ShinyDashboardAks from "../components/Factory/Pages/ShinyDashboard/ShinyDashboardAks";
import EmailTemplateForm from "../components/Factory/Pages/EmailEditor/EmailTemplateForm";
import Header from "../components/Factory/Pages/ChartEditor/Editor/Header/Header";
import ReactSummernote from "../components/Factory/Molecules/Common/SummerNote/M_SummerNotes";
import Master from "../components/Factory/Pages/Master/Master";
import Tenant from "../components/Factory/Pages/Master/Tenant/Tenant";
import MasterForm from "../components/Factory/Pages/Master/MasterForm";
import TenantForm from "../components/Factory/Pages/Master/Tenant/TenantForm";
import ChecklistEditor from "../components/Factory/Pages/ChecklistEditor/ChecklistEditor";
import ChecklistEditorForm from "../components/Factory/Pages/ChecklistEditor/ChecklistEditorForm";
import DataSourceEditor from "../components/Factory/Pages/DataSourceEditor/DataSourceEditor";
import DataSourceEditorForm from "../components/Factory/Pages/DataSourceEditor/DataSourceEditorForm";
import forceLogout from "../components/Factory/Pages/Common/forceLogout";
import MenuEditor from "../components/Factory/Pages/MenuEditor/MenuEditor";
import MenuEditorForm from "../components/Factory/Pages/MenuEditor/MenuEditorForm";
const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, store);

export default function Routes(props) {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/" component={LoginPage} />
                <Route path="/securelogout" component={forceLogout} />
                <Route path="/dashboard" component={MainPage} />
                <Route path="/callback" component={CallbackPage} />
                <Route exact path="/pageeditor" component={PageEditor} />
                <Route exact path="/summernote" component={ReactSummernote} />
                <Route
                    exact
                    path="/editormain/:id"
                    component={EditorMainPage}
                />
                <Route exact path="/charteditor" component={ChartEditor} />
                <Route exact path="/emaileditor" component={EmailEditor} />
                <Route
                    exact
                    path="/checklisteditor"
                    component={ChecklistEditor}
                />
                <Route
                    exact
                    path="/ChecklistEditorForm/:id"
                    component={ChecklistEditorForm}
                />
                <Route
                    exact
                    path="/shinydashboards"
                    component={ShinyDashboard}
                />
                <Route
                    exact
                    path="/shinydashboardsaks"
                    component={ShinyDashboardAks}
                />
                <Route path="/playground" component={M_Playground} />
                <Route
                    exact
                    path="/ChartConfigurationForm/:id"
                    component={ChartMainPage}
                />
                <Route exact path="/chart" component={Header} />
                <Route
                    exact
                    path="/EmailTemplateForm/:id"
                    component={EmailTemplateForm}
                />
                <Route exact path="/Master" component={Master} />
                <Route exact path="/MasterForm/:id" component={MasterForm} />
                <Route exact path="/Tenant" component={Tenant} />
                <Route exact path="/TenantForm/:id" component={TenantForm} />
                <Route
                    exact
                    path="/DataSourceEditor"
                    component={DataSourceEditor}
                />
                <Route
                    exact
                    path="/DataSourceEditorForm/:id"
                    component={DataSourceEditorForm}
                />
                <Route
                    exact
                    path="/MenuEditor"
                    component={MenuEditor}
                />
                <Route
                    exact
                    path="/MenuEditorForm/:id"
                    component={MenuEditorForm}
                />
            </Switch>
        </Router>
    );
}
