import { pageEditorActionTypes } from "../actions/actionTypes";

let initialState = [];
const pageEditorReducer = (state = initialState, action) => {
    switch (action.type) {
        case pageEditorActionTypes.GET_PAGES:
            return { getPagesStarted: true };
        case pageEditorActionTypes.GET_PAGES_SUCCESSED:
            return action.payload;
        case pageEditorActionTypes.GET_PAGES_FAILED:
            return action.payload;
        default:
            return state;
    }
};
export default pageEditorReducer;
