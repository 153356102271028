import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as pageTemplatesActions from "../../../../../../../store/actions/pageTemplatesActions";
import TemplateEditorMain from "../Editor/TemplateEditorMain";
import A_EnhancedTable from "../../../../../Atoms/DataDisplay/DataGrid/A_EnhancedTable";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "1000px",
        height: "600px",
    },
    iconDiv: {
        right: "50px",
        position: "absolute",
        width: "24px !important",
        height: " 24px !important",
    },
    editIcon: { float: "right" },
    addIcon: {
        fontSize: "1.75em",
        right: "-30px",
        position: "absolute",
        Color: "black",
    },
    table: {
        minWidth: 650,
    },
    tableDiv: {
        marginTop: "55px",
    },
}));

const M_Templates = (props) => {
    const defaultTemplateData = {
        id: null,
        templateType: "view",
        templateName: "",
        description: "",
        helpArticle: "",
        pageId: "", //props.currentPage.id
        templateJson: "",
        sectionHeader: "",
        isRestricted: false,
        templateSecurity: {
            read: {
                groupids: [""],
                users: [],
            },
            update: {
                groupids: [""],
                users: [],
            },
            full: {
                groupids: [""],
                users: [],
            },
        },
    };

    const [currentRow, setCurrentRow] = useState(defaultTemplateData);
    const [data, setData] = useState([]);
    const [editable, setEditable] = useState(false);

    const classes = useStyles();
    const history = useHistory();
    const colmunConfiguration = {
        title: "",
        noDataText: "No data available.",
        paginate: true,
        searchable: true,
        sortable: false,
        striped: true,
        addButton: true,
        action: {
            edit: true,
            delete: false,
        },
        columnConfig: {
            name: {
                id: "name",
                label: "Name",
                sorting: false,
                hide: false,
            },
            description: {
                id: "description",
                label: "Description",
                sorting: false,
                hide: false,
            },
            templateType: {
                id: "templateType",
                label: "Type",
                sorting: false,
                hide: false,
            },
            helpArticle: {
                id: "helpArticle",
                label: "helpArticle",
                sorting: false,
                hide: true,
            },
            status: {
                id: "status",
                label: "status",
                sorting: false,
                hide: true,
            }
        },
    };

    useEffect(() => {
        props.getPageTemplates({
            tenantId: props.tenantId,
            pageId: props.currentPage.id,
        });
    }, []);

    useEffect(() => {
        setData(props.pageTemplates);
    }, [props.pageTemplates]);

    const handleAdd = () => {
        props.setCurrentTemplate(defaultTemplateData);
        setCurrentRow(defaultTemplateData);
        setEditable(true);
    };
    const handleEdit = (e) => {
        let row = data.filter((template) => {
            return template.id === e.currentTarget.id;
        })[0];
        props.setCurrentTemplate(row);
        setCurrentRow(row);
        setEditable(true);
    };
    const handleBackFromTemplateEditor = () => {
        setCurrentRow({ defaultTemplateData });
        setEditable(false);
    };
    const handleOnSave = (action) => {
        // if(action==='add'){
        //     let row=data[data.length-1];
        //     // let row=data.filter((template)=>{
        //     //     return template.id===newTemplate.id
        //     // })[0];
        //     setCurrentRow(row);
        //     setEditable(true);
        //}
        props.getPageTemplates({
            tenantId: props.tenantId,
            pageId: props.currentPage.id,
        });  
    };

    return (
        <section className={classes.root}>
            {editable ? (
                <>
                    <TemplateEditorMain
                        tenantId={props.tenantId}
                        generalData={currentRow}
                        onSave={handleOnSave}
                        pageTemplates={data}
                        onBackFromTemplateEditor={handleBackFromTemplateEditor}
                    />
                </>
            ) : (
                <>
                    <A_EnhancedTable
                        id="templates-table"
                        data={data}
                        configurations={colmunConfiguration}
                        handleAddButtonClick={(e) => handleAdd(e)}
                        handleEditButtonClick={(e) => handleEdit(e)}
                        handleDeleteButtonClick={(e) => handleDelete(e)}
                    />
                </>
            )}
        </section>
    );
};
function mapStateToProps(state) {
    return {
        pageTemplates: state.pageTemplates.data,
        currentPage: state.currentPage,
    };
}
function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(pageTemplatesActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(M_Templates);
