import React from "react";
import * as currentChecklistActions from "../../../../store/actions/currentChecklistActions";
import A_Snackbar from "../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_EnhancedTable from "../../Atoms/DataDisplay/DataGrid/A_EnhancedTable";
import * as checklistEditorActions from "../../../../store/actions/checklistEditorActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import * as tenantsActions from "../../../../store/actions/tenantsActions";
import Header from "../../Pages/PageEditor/MainPage/Header/Header";
import * as checklistFieldsActions from "../../../../store/actions/checklistFieldsActions";
import A_SimpleDialog from "../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
const useStyles = makeStyles((theme) => ({
    List: {
        marginLeft: "10px",
        marginRight: "10px",
    },
}));
let colmunConfiguration = {
    title: "",
    noDataText: "No data available.",
    paginate: true,
    searchable: true,
    sortable: false,
    striped: true,
    addButton: true,
    action: {
        edit: true,
        delete: true,
    },
    columnConfig: {
        checklistName: {
            id: "checklistName",
            label: "Checklist Name",
            sorting: false,
            hide: false,
        },
      
        workflowState: {
            id: "workflowState",
            label: "Workflow State",
            sorting: false,
            hide: false,
        },
       type: {
            id: "type",
            label: "Deal Type",
            sorting: false,
            hide: false,
        },
        checklistPurpose: {
            id: "checklistPurpose",
            label: "Description",
            sorting: false,
            hide: false,
        }, 
    },
};

const ChecklistEditor = (props) => {
    const classes = useStyles();
    const history = useHistory();
    let isDisabledTenants = false;
    const [changesSaved, setChangesSaved] = React.useState({
        status: false,
        msg: "Data deleted successfully",
    });
    const [currentTenant, setCurrentTenant] = React.useState({
        id: "",
        name: "",
    });
    //const [deleting, setDeleting] = React.useState(false);
    const [deletePopup, setDeletePopup] = React.useState(false);
    const [currenteleId, setCurrenteleId] = React.useState("");
    const [checklistData, setChecklistData] = React.useState(
        props.checklistEditor &&
            props.checklistEditor.data &&
            props.checklistEditor.data != "Empty"
            ? props.checklistEditor.data
            : []
    );

    let filterChecklistData = checklistData.map((item) => {
        return {
            checklistName: item.checklistName,
            workflowState: item.workflowState,
            checklistSection: item.checklistSection,
            id: item.id,
            type: item.type,
            status: item.status,
            sectionOrder: item.sectionOrder,
            checklistPurpose: item.checklistPurpose,
        };
    });
    const getChecklistDetails = (currentTenant) => {
        props.getChecklist(currentTenant);
        props.getChecklistFields(currentTenant);
    };
    React.useEffect(() => {
        props.getTenants();
    }, []);
    const handleSnackbarClose = () => {
        setChangesSaved({ status: false, msg: "", type: "success" });
    };
    React.useEffect(() => {
        if (props.checklistEditor.data && props.checklistEditor) {
            setChecklistData(props.checklistEditor.data);
        }
    }, [props.checklistEditor]);

    React.useEffect(() => {
        if (currentTenant.id) {
            getChecklistDetails(currentTenant.id);
        } else if (props.checklistEditor.currentTenant) {
            let currTenant = props.tenants.filter((checklistEditor) => {
                return (
                    checklistEditor.tenantId ==
                    props.checklistEditor.currentTenant
                );
            })[0];
            setCurrentTenant({
                id: currTenant.tenantId,
                name: currTenant.tenantName,
            });
            getChecklistDetails(currTenant.tenantId);
        }
        return () => {
            if (props.match.params.back == "back") {
                props.resetPages();
            }
        };
    }, [currentTenant.id]);
    const tenantChangeHandler = (e) => {
        let param = e;
        let currTenant = props.tenants.filter((checklistEditor) => {
            return checklistEditor.tenantId == param;
        })[0];
        setCurrentTenant({
            id: currTenant.tenantId,
            name: currTenant.tenantName,
        });
    };
    React.useEffect(() => {
        if (props.checklistEditor.checklistDeleted) {
            let arr = checklistData.filter((item) => currenteleId != item.id);
            setChecklistData(arr);
        }
    }, [props.checklistEditor && props.checklistEditor.checklistDeleted]);
    const handleDeleteButtonClick = (e) => {
        let id = e.currentTarget.getAttribute("dataid");
        let arr = filterChecklistData.filter((item) => id == item.id);
        let checklistid = arr[0].id;
        setCurrenteleId(checklistid);
        setDeletePopup(true);
    };
    const handleConfirmDelete = () => {
        setChangesSaved({ status: true, msg: "Data deleted successfully" });
        props.deleteChecklist({
            checklistId: currenteleId,
            tenantId: currentTenant.id,
        });
        setDeletePopup(false);
    };
    const handleCancelDelete = () => {
        setDeletePopup(false);
    };
    const getActionItems = () => {
        return (
            <>
                <A_Button
                    color="primary"
                    label={"Ok"}
                    autoFocus
                    onClick={handleConfirmDelete}
                />

                <A_Button
                    color="default"
                    label={"Cancel"}
                    onClick={handleCancelDelete}
                />
            </>
        );
    };
    const handleEditButtonClick = (e) => {
        let arr = filterChecklistData.filter(
            (item) => e.currentTarget.id == item.id
        );
        let id = arr[0].id;
        props.setCurrentChecklist(arr[0]);
        let path = "/ChecklistEditorForm/" + id;
        history.push({ pathname: path });
    };
    const handleAddButtonClickEvent = (e) => {
        let data = {
            checklistName: "",
            workflowState: "",
            checklistSection: "",
            type: "",
            status: "1",
            sectionOrder: "",
            checklistPurpose: "",
            id: 0,
        };
        props.setCurrentChecklist(data);
        let path = "/ChecklistEditorForm/" + 0;
        history.push({ pathname: path });
    };
    return (
        <>
            <Header
                id="toolbar"
                title="Checklist Editor"
                TenantData={props.tenants}
                value={currentTenant.id || props.checklistEditor.currentTenant}
                isdisabledFlag={isDisabledTenants}
                tenantChangeHandlerCallback={(e, name) =>
                    tenantChangeHandler(e, name)
                }
            />
            {currentTenant && filterChecklistData.length > 0 ? (
                <A_EnhancedTable
                    data={filterChecklistData}
                    configurations={colmunConfiguration}
                    handleAddButtonClick={handleAddButtonClickEvent}
                    handleDeleteButtonClick={handleDeleteButtonClick}
                    handleEditButtonClick={handleEditButtonClick}
                    className={classes.List}
                />
            ) : (
                ""
            )}
            <A_SimpleDialog
                id="2"
                open={deletePopup}
                dialogContent={"Are you sure you want to delete this data ?"}
                dialogActions={getActionItems()}
            />

            <A_Snackbar
                open={changesSaved.status}
                message={changesSaved.msg}
                autoHideDuration={5000}
                horizontal="center"
                handleClose={handleSnackbarClose}
                type={changesSaved.type}
            />
        </>
    );
};
function mapStateToProps(state) {
    return {
        checklistEditor: state.checklistEditor,
        currentChecklist: state.currentChecklist,
        tenants: state.tenants.data,
    };
}
function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        {
            ...checklistEditorActions,
            ...currentChecklistActions,
            ...tenantsActions,
            ...checklistFieldsActions,
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ChecklistEditor);
