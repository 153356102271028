import { isRequired, isExists } from "../../../../Utils/Helpers/validations";
import A_CircularIndeternment from "../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
import { bindActionCreators } from "redux";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import A_Snackbar from "../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_Select from "../../Atoms/Inputs/Selects/A_Select";
import A_Textbox from "../../Atoms/Inputs/TextFields/A_TextField";
import * as currentChecklistItemActions from "../../../../store/actions/currentChecklistItemActions";

const useStyles = makeStyles((theme) => ({
    container: {
        overflow: "auto",
        "& .MuiFormControl-root": {
            margin: theme.spacing(1),
            width: 440,
        },
        margin: "20px 0px 0px 60px",
    },
    left: {
        float: "left",
        width: "80%",
    },
    right: {
        float: "right",
    },
    hideSchedule: {
        display: "none",
    },
    checkbox: {
        height: "20px",
        width: "20px",
        marginTop: "10px",
        marginLeft: "20px",
    },
    alignSchedule: {
        marginTop: "12px",
    },
}));
const ChecklistEditorItems = (props) => {
    const history = useHistory();
    const classes = useStyles();

    const itemTypes = [
        {
            id: "checkbox",
            label: "checkbox",
            value: "checkbox",
        },

        {
            id: "optionset",
            label: "optionset",
            value: "optionset",
        },
    ];
    const [formData, setFormData] = useState(
        props.currentChecklistItem.convertedData || props.data ? props.data : {}
    );
    const handleFormData = (e) => {
        let field = e.target.id;
        let newFormData = formData;
        newFormData[field] = e.target.value;
        setFormData(newFormData);
    };
    const handleDropdowns = (selectedOption, id) => {
        let newFormData = formData;
        newFormData[id] = selectedOption.value;
        setFormData(newFormData);
        if (id === "itemResponseType") {
            if (selectedOption.value === "optionset") {
                setResponseOptionSet(true);
            } else {
                setResponseOptionSet(false);
            }
        }
        if (id === "itemSkipType") {
            if (selectedOption.value === "optionset") {
                setSkipOptionSet(true);
            } else {
                setSkipOptionSet(false);
            }
        }
    };
    const handleTitleCheckbox = (e) => {
        if (formData.itemStatus === false) {
            formData.itemStatus = true;
        } else {
            formData.itemStatus = false;
        }
    };
    const [validationSummary, setValidationSummary] = useState({});
    const [responseOptionSet, setResponseOptionSet] = useState(
        formData &&
            formData.itemResponseType &&
            formData.itemResponseType === "optionset" &&
            formData.responseOptionset
            ? true
            : false
    );
    const [skipOptionSet, setSkipOptionSet] = useState(
        formData &&
            formData.itemSkipType &&
            formData.itemSkipType === "optionset" &&
            formData.skipOptionset
            ? true
            : false
    );
    let snackBarMsg = "Changes saved successfully";
    const [saving, setSaving] = useState(false);
    const [changesSaved, setChangesSaved] = useState({
        status: false,
        type: "success",
        msg: snackBarMsg,
    });
    useEffect(() => {
        if (saving) {
            setChangesSaved({
                status: true,
                msg: "Data saved successfully",
                type: "success",
            });
            setSaving(false);
        }
    }, [props.currentChecklistItem]);

    useEffect(() => {
        if (saving) {
            setChangesSaved({
                status: true,
                msg: props.currentChecklistItem.errorMessage,
                type: "error",
            });
            setSaving(false);
        }
    }, [props.currentChecklistItem.errorMessage]);

    const handleSnackbarClose = () => {
        setChangesSaved({ status: false, msg: "" });
        props.onSave();
    };
    const handleValidations = () => {
        let validations = {};
        setValidationSummary({ error: false, msg: "" });
        let isValidated = true;
        if (!isRequired(formData.itemDescription)) {
            isValidated = false;
            validations.itemDescription = {
                error: true,
                msg: "Description is required",
            };
        }
        if (!isRequired(formData.docRequired)) {
            isValidated = false;
            validations.docRequired = {
                error: true,
                msg: "Doc is required",
            };
        }
        if (!isRequired(formData.itemOrder)) {
            isValidated = false;
            validations.itemOrder = {
                error: true,
                msg: "Order is required",
            };
        }
        if (!isRequired(formData.itemResponseType)) {
            isValidated = false;
            validations.itemResponseType = {
                error: true,
                msg: "Type is required",
            };
        }

        if (responseOptionSet && !isRequired(formData.responseOptionset)) {
            isValidated = false;
            validations.responseOptionset = {
                error: true,
                msg: "Optionset is required",
            };
        }
        setValidationSummary(validations);
        return isValidated;
    };
    const handleSave = async () => {
        if (formData.checkListSectionMasterId === undefined) {
            formData.checkListSectionMasterId =
                props.currentChecklistItem.convertedData.checkListSectionMasterId;
        }
        let tenantId = props.tenantId;

        if (handleValidations()) {
            let data = {
                formData,
                tenantId,
            };

            props.saveCurrentChecklistItem(data);
            setSaving(true);
        }
    };
    const handleBack = () => {
        props.onSave();
    };
    if (formData.checkListSectionMasterId === "") {
        formData.checkListSectionMasterId = props.checklistMasterId;
    }
    return (
        <>
            <section className={classes.container}>
                <div name="newdata" className={classes.left}>
                    <h3>Add Data</h3>
                    <br />
                    <Grid container alignItems="flex-start" spacing={0}>
                        <Grid item xs={3}>
                            <Typography className={classes.label} variant="h6">
                                Description
                                <span className="requiredMark">*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <A_Textbox
                                variant="outlined"
                                size="medium"
                                id="itemDescription"
                                defaultValue={formData.itemDescription}
                                fullWidth={true}
                                error={
                                    validationSummary &&
                                    validationSummary.itemDescription &&
                                    validationSummary.itemDescription.error
                                        ? validationSummary.itemDescription
                                              .error
                                        : null
                                }
                                helperText={
                                    validationSummary &&
                                    validationSummary.itemDescription &&
                                    validationSummary.itemDescription.msg
                                        ? validationSummary.itemDescription.msg
                                        : null
                                }
                                onChange={handleFormData}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className={classes.label} variant="h6">
                                Parent
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <A_Textbox
                                variant="outlined"
                                size="medium"
                                id="itemParent"
                                defaultValue={formData.itemParent}
                                fullWidth={true}
                                multiline={true}
                                rows={3}
                                onChange={handleFormData}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className={classes.label} variant="h6">
                                Dependency
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <A_Textbox
                                variant="outlined"
                                size="medium"
                                id="dependency"
                                defaultValue={formData.dependency}
                                fullWidth={true}
                                multiline={true}
                                rows={3}
                                onChange={handleFormData}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className={classes.label} variant="h6">
                                Doc Required
                                <span className="requiredMark">*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <A_Textbox
                                variant="outlined"
                                size="medium"
                                id="docRequired"
                                type="number"
                                defaultValue={formData.docRequired}
                                fullWidth={true}
                                rows={3}
                                error={
                                    validationSummary &&
                                    validationSummary.docRequired &&
                                    validationSummary.docRequired.error
                                        ? validationSummary.docRequired.error
                                        : null
                                }
                                helperText={
                                    validationSummary &&
                                    validationSummary.docRequired &&
                                    validationSummary.docRequired.msg
                                        ? validationSummary.docRequired.msg
                                        : null
                                }
                                onChange={handleFormData}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className={classes.label} variant="h6">
                                Order
                                <span className="requiredMark">*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <A_Textbox
                                variant="outlined"
                                size="medium"
                                id="itemOrder"
                                type="number"
                                defaultValue={formData.itemOrder}
                                fullWidth={true}
                                rows={3}
                                error={
                                    validationSummary &&
                                    validationSummary.itemOrder &&
                                    validationSummary.itemOrder.error
                                        ? validationSummary.itemOrder.error
                                        : null
                                }
                                helperText={
                                    validationSummary &&
                                    validationSummary.itemOrder &&
                                    validationSummary.itemOrder.msg
                                        ? validationSummary.itemOrder.msg
                                        : null
                                }
                                onChange={handleFormData}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className={classes.label} variant="h6">
                                Status
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <label>
                                <input
                                    id="itemStatus"
                                    type="checkbox"
                                    defaultChecked={formData.itemStatus}
                                    onChange={handleTitleCheckbox}
                                    className={classes.checkbox}
                                />
                            </label>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className={classes.label} variant="h6">
                                Response Column Type
                                <span className="requiredMark">*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <A_Select
                                id="itemResponseType"
                                options={itemTypes}
                                defaultValue={formData.itemResponseType}
                                onChange={handleDropdowns}
                                error={
                                    validationSummary &&
                                    validationSummary.itemResponseType &&
                                    validationSummary.itemResponseType.error
                                        ? validationSummary.itemResponseType
                                              .error
                                        : null
                                }
                                helperText={
                                    validationSummary &&
                                    validationSummary.itemResponseType &&
                                    validationSummary.itemResponseType.msg
                                        ? validationSummary.itemResponseType.msg
                                        : null
                                }
                            />
                        </Grid>
                        {responseOptionSet && (
                            <>
                                <Grid item xs={3}>
                                    <Typography
                                        className={classes.label}
                                        variant="h6"
                                    >
                                        Response OptionSet Values
                                        <span className="requiredMark">*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <A_Textbox
                                        variant="outlined"
                                        size="medium"
                                        id="responseOptionset"
                                        defaultValue={
                                            formData.responseOptionset
                                        }
                                        fullWidth={true}
                                        error={
                                            validationSummary &&
                                            validationSummary.responseOptionset &&
                                            validationSummary.responseOptionset
                                                .error
                                                ? validationSummary
                                                      .responseOptionset.error
                                                : null
                                        }
                                        helperText={
                                            validationSummary &&
                                            validationSummary.responseOptionset &&
                                            validationSummary.responseOptionset
                                                .msg
                                                ? validationSummary
                                                      .responseOptionset.msg
                                                : "Comma Seperated Values need to be entered"
                                        }
                                        onChange={handleFormData}
                                    />
                                </Grid>
                            </>
                        )}

                        <Grid item xs={3}>
                            <Typography className={classes.label} variant="h6">
                                Doc Link
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <A_Textbox
                                variant="outlined"
                                size="medium"
                                id="checklistItemDoclink"
                                helperText=""
                                defaultValue={formData.checklistItemDoclink}
                                fullWidth={true}
                                onChange={handleFormData}
                            />
                        </Grid>
                    </Grid>
                </div>

                <div
                    className={classes.left}
                    style={{ marginLeft: "40px", marginTop: "20px" }}
                >
                    <A_Button
                        color="primary"
                        size="medium"
                        label="Back"
                        onClick={handleBack}
                    />
                    &nbsp;&nbsp;
                    <A_Button
                        color="primary"
                        size="medium"
                        label="Save"
                        disabled={saving}
                        endIcon={
                            saving ? (
                                <A_CircularIndeternment
                                    color="secondary"
                                    size={20}
                                />
                            ) : (
                                ""
                            )
                        }
                        onClick={handleSave}
                    />
                </div>
                <A_Snackbar
                    open={changesSaved.status}
                    message={changesSaved.msg}
                    type={changesSaved.type}
                    autoHideDuration={1800}
                    horizontal="center"
                    handleClose={handleSnackbarClose}
                />
            </section>
        </>
    );
};
function mapStateToProps(state) {
    return {
        currentChecklistItem: state.currentChecklistItem,
    };
}
function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign({}, currentChecklistItemActions),
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChecklistEditorItems);
