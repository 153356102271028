import { take, put, select } from "redux-saga/effects";
import axios from "axios";
import {chartEditorActionTypes} from "../actions/actionTypes";
import * as chartEditorActions  from "../actions/chartEditorActions";
import * as commonFunctions from "../../Utils/Helpers/common";


export function* getChartsSaga() {
    while(true){
        const action = yield take(chartEditorActionTypes.GET_CHARTS);
        const payload=action.payload;    
        let config=commonFunctions.getHttpConfig();
        try {           
            
            const res = yield axios.get(`${process.env.API_URL}/${payload}/ChartConfiguration`,config);          
            console.log('getChartSaga response:', res);
            let convertedData= res.data==='Empty'? []: res.data; 
            yield put(
                chartEditorActions.getChartsSuccessed({data:convertedData,currentTenant: payload, getChartStarted: false, fetchChartsFailed: false})
            );
            
          
          } catch (e) {
            chartEditorActions.getChartsFailed({msg: 'Some error occured',getChartStarted: false, fetchChartsFailed: true})
            console.log("can not fetch page configurations.");
            console.log(e.message);
            
          }
    }
}
