import { take, put } from "redux-saga/effects";
import axios from "axios";
import { currentMasterActionTypes } from "../actions/actionTypes";
import * as currentMasterActions from "../actions/currentMasterActions";
import * as masterConfigurationActions from "../actions/masterConfigurationActions";
import * as commonFunctions from "../../Utils/Helpers/common";
export function* saveCurrentMasterSaga() {
    while (true) {
        const action = yield take(currentMasterActionTypes.SAVE_CURRENT_MASTER);
        const MasterData = action.payload;
        let config = commonFunctions.getHttpConfig();
        if (
            MasterData.ParametersValues != undefined ||
            MasterData.ParametersValues != null
        ) {
            let apiUrl = `${process.env.API_URL}/MasterConfigurations/SaveMasterConfigurationKeys`;
            try {
                const res = yield axios.post(apiUrl, MasterData, config);
                let data = {
                    Key: res.data.message.ParametersValues.Key,
                    KeyType: res.data.message.ParametersValues.KeyType,
                    Value: res.data.message.ParametersValues.Value,
                    DefaultValue:
                        res.data.message.ParametersValues.DefaultValue,
                    DataType: res.data.message.ParametersValues.DataType,
                    id: res.data.message.id,
                };
                yield put(
                    currentMasterActions.saveCurrentMasterSucceeded({
                        ...data,
                    })
                );
                yield put(
                    masterConfigurationActions.getMasterConfigurationDetails()
                );
            } catch (e) {
                yield put(
                    currentMasterActions.saveCurrentMasterFailed({
                        ...MasterData,
                        saveCurrentMasterFailed: true,
                        errorMessage: e.response.data.Message
                            ? e.response.data.message
                            : Object.values(e.response.data.errors)[0][0],
                    })
                );
            }
        } else {
            let apiUrl = `${process.env.API_URL}/MasterConfigurations/update`;
            try {
                const res = yield axios.put(apiUrl, MasterData, config);
                yield put(
                    currentMasterActions.saveCurrentMasterSucceeded({
                        MasterData,
                    })
                );
                yield put(
                    masterConfigurationActions.getMasterConfigurationDetails({})
                );
            } catch (e) {
                yield put(
                    currentMasterActions.saveCurrentMasterFailed({
                        ...MasterData,
                        saveCurrentMasterFailed: true,
                    })
                );
            }
        }
    }
}
