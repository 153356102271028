export const fieldTypes = [
    {
        id: "string",
        label: "string",
        value: "string",
    },
    {
        id: "bit",
        label: "bit",
        value: "bit",
    },
    {
        id: "date",
        label: "date",
        value: "date",
    },
    {
        id: "lookup",
        label: "lookup",
        value: "lookup",
    },
    {
        id: "optionset",
        label: "optionset",
        value: "optionset",
    },
    {
        id: "Document",
        label: "document",
        value: "Document",
    },
    {
        id: "PortfolioFileUpload",
        label: "PortfolioFileUpload",
        value: "PortfolioFileUpload",
    },
    {
        id: "percentage",
        label: "percentage",
        value: "percentage",
    },
    {
        id: "integer",
        label: "integer",
        value: "integer",
    },
    {
        id: "float",
        label: "float",
        value: "float",
    },
    {
        id: "number",
        label: "number",
        value: "number",
    },
    {
        id: "multiple",
        label: "multiple",
        value: "multiple",
    },
    {
        id: "html",
        label: "html",
        value: "html",
    },
    {
        id: "table",
        label: "table",
        value: "table",
    },
    {
        id: "text",
        label: "text",
        value: "text",
    },
    {
        id: "radios",
        label: "radios",
        value: "radios",
    },
     {
        id: "radioevent",
        label: "radioevent",
        value: "radioevent",
    },
    {
        id: "button",
        label: "button",
        value: "button",
    },
    {
        id: "section",
        label: "section",
        value: "section",
    },
    {
        id: "username",
        label: "username",
        value: "username",
    },
    {
        id: "multiselect",
        label: "multiselect",
        value: "multiselect",
    },
    {
        id: "financial",
        label: "financial",
        value: "financial",
    },
];