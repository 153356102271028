import React from "react";
import A_AppBar from "../../../../Atoms/Surfaces/Appbars/A_AppBar";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import M_Dropdown from "../../../../Molecules/Common/Dropdown/M_Dropdown";

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        alignSelf: "flex-end",
        fontWeight: "lighter",
    },
    appbar: {
        height: "auto",
    },
    subheading: {
        display: "flex",
        justifyContent: "space-between",
        marginLeft: "20px",
        marginBottom: "10px",
        marginRight: "20px",
    },
    Heading: {
        fontWeight: "lighter",
        marginTop: "10px",
        fontSize: "18px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
        },
    },
    title2: {
        fontSize: "26px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
        },
    },
}));

const Header = (props) => {
    const classes = useStyles();

    return (
        <div>
            <A_AppBar
                className={classes.appbar}
                position="static"
                content={
                    <div>
                        {" "}
                        <div className={classes.subheading}>
                            <Typography edge="start" className={classes.title2}>
                                {props.title}
                            </Typography>
                            <M_Dropdown
                                id={props.id}
                                data={props.TenantData}
                                value={props.value}
                                isdisabled={props.isdisabledFlag}
                                changeChandle={(e, name) =>
                                    props.tenantChangeHandlerCallback(e, name)
                                }
                                edge="end"
                            />
                        </div>
                    </div>
                }
            ></A_AppBar>
        </div>
    );
};
export default Header;
