import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Grid } from "@material-ui/core";
import A_Textbox from "../../../../Atoms/Inputs/TextFields/A_TextField";
import M_RadiosGroup from "../../../../Molecules/Common/M_RadiosGroup";
import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";
import A_CircularIndeternment from "../../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import A_Snackbar from "../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import ChartEditorMain from "../ChartEditorRequest/MainPage/MainRequestPage";
import ChartEditorResult from "../ChartEditorResult/MainPage/MainResultPage";
import A_SimpleDialog from "../../../../../Factory/Atoms/Feedback/Dialogs/A_SimpleDialog";
import {
    isRequired,
    isValidJSON,
    isExistsMoreThan1Time,
    isExists,
} from "../../../../../../Utils/Helpers/validations";
import { useHistory } from "react-router-dom";

import * as chartEditorActions from "../../../../../../store/actions/chartEditorActions";
import * as currentChartActions from "../../../../../../store/actions/currentChartActions";
import * as chartPreviewActions from "../../../../../../store/actions/chartPreviewActions";

const useStyles = makeStyles((theme) => ({
    container: {
        overflow: "auto",
        "& .MuiFormControl-root": {
            margin: theme.spacing(1),
            width: 440,
        },
        margin: "20px 0px 0px 60px",
    },
    left: {
        float: "left",
        width: "80%",
    },
    right: {
        float: "right",
    },
}));

const ChartConfigurationForm = (props) => {
    const defaultFormData = {
        chartId: null,
        chartName: "",
        description: "",
        type: "",
        javaScript: "",
        configuration: "",
        query: {
            source: "",
            spName: "",
            parameters: [],
        },
        resultParameters: "",
    };
    const history = useHistory();
    const classes = useStyles();
    const [value, setValue] = useState(0);
    let snackBarMsg = "Changes saved successfully";
    const [openDialog, setOpenDialog] = useState({ status: false, msg: "" });
    const [javascriptConfig, setJavascriptConfig] = React.useState("");
    const [saving, setSaving] = useState(false);
    const [isAdd, setIsAdd] = useState(
        props.currentChart.id === null ? true : false
    );
    const [validationSummary, setValidationSummary] = useState({
        chartName: {},
    });
    const handleJavascriptConfig = (javascriptConfig) => {
        setJavascriptConfig(javascriptConfig);
    };

    const [configurationEditor, setConfigurationEditor] = useState("");

    const handleConfigurationEditor = (configurationEditor) => {
        setConfigurationEditor(configurationEditor);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [formData, setFormData] = useState(
        props.currentChart ? props.currentChart : {}
    );
    const [changesSaved, setChangesSaved] = useState({
        status: false,
        type: "success",
        msg: snackBarMsg,
    });
    const [jsError, setJSError] = useState(false);
    const handleFormData = (e) => {
        let field = e.target.id;
        let newFormData = formData;
        newFormData[field] = e.target.value;
        setFormData({ ...formData, field: e.target.value });
        props.setCurrentChart({ ...formData, field: e.target.value });
    };

    const handleChartType = (selectedRadio) => {
        let newFormData = formData;
        /*  selectedRadio.value="graph" */
        newFormData.type = selectedRadio.value;

        setFormData({ ...formData, type: selectedRadio.value });

        props.setCurrentChart({ ...formData, type: selectedRadio.value });
        props.setChartPreview({
            ...props.chartPreview,
            type: selectedRadio.value,
        });
    };

    const type = [
        {
            id: "graph",
            label: "Graph",
            value: "graph",
        },
        {
            id: "table",
            label: "Table",
            value: "table",
        },
    ];

    const handleValidations = () => {
        let validations = { chartName: {} };

        setValidationSummary({ error: false, msg: "" });
        //Field Name
        let isValidated = true;
        let name =
            formData.chartName === undefined
                ? props.currentChart.chartName
                : formData.chartName;
        if (!isRequired(formData.chartName)) {
            isValidated = false;
            validations.chartName = { error: true, msg: "Name is required" };
        }

        if (isAdd) {
            let isDuplicate = isExists(
                formData.chartName,
                "chartName",
                props.chartEditor.data
            );
            if (isDuplicate) {
                isValidated = false;
                validations.chartName = {
                    error: true,
                    msg: "Name is already exists.",
                };
            }
        } else {
            let isDuplicate = isExistsMoreThan1Time(
                formData.chartName,
                "chartName",
                props.chartEditor.data
            );
            if (isDuplicate) {
                isValidated = false;
                validations.chartName = {
                    error: true,
                    msg: "Name is already exists.",
                };
            }
        }
        // if (!isValidJSON(props.currentChart.configuration, true)) {
        //     isValidated = false;
        //     setOpenDialog({
        //         status: true,
        //         msg: " JSON is invalid.",
        //     });
        // }
        if (jsError) {
            isValidated = false;
            setOpenDialog({
                status: true,
                msg: " JavaScript is invalid.",
            });
        }
        setValidationSummary(validations);
        return isValidated;
    };

    const handleSave = async () => {
        if (handleValidations()) {
            let chartData = props.currentChart;
            chartData.chartName =
                "chartName" in formData
                    ? formData.chartName
                    : chartData.chartName;
            let tenantId = props.chartEditor.currentTenant;
            let formObject = {
                currentChartData: props.currentChart,
                tenantId: tenantId,
            };
            // loading starts
            setSaving(true);
            props.setChartPreview({
                ...props.currentChart,
                currentChartSaved: undefined,
            });
            // saving starts
            props.saveCurrentChart(formObject);
        }
    };

    React.useEffect(() => {
        if (props.currentChart.errorMessage && saving) {
            // loading ends
            setSaving(false);
            // saved  message
            setChangesSaved({
                status: true,
                type: "error",
                msg: props.currentChart.errorMessage,
            });
        }
    }, [props.currentChart && props.currentChart.errorMessage]);
    React.useEffect(() => {
        if (props.user && props.user === null) {
            // loading ends
            setSaving(true);
            // saved  message
        }
    }, [props.user === null]);

    React.useEffect(() => {
        if (props.currentChart.currentChartSaved && saving) {
            // saved  message
            setChangesSaved({
                status: true,
                msg: "Changes saved successfully",
            });
            // loading ends
            setSaving(false);

            // when no js error is there , then load preview
            if (jsError === false) {
                props.setChartPreview({
                    ...props.currentChart,
                    currentChartSaved: undefined,
                });
            }
            let id = props.currentChart.id;
            if (id) {
                let tenantId = props.chartEditor.currentTenant;
                // get chart data
                props.getPreviewData({ id: id, tenantId: tenantId });
            }
        }
    }, [props.currentChart && props.currentChart.currentChartSaved]);

    const handleSnackbarClose = () => {
        setChangesSaved({ status: false, msg: "" });
    };

    const handleSimpleDialogClose = (value) => {
        setOpenDialog({ status: false, msg: "" });
    };
    React.useEffect(() => {
        let id = props.currentChart.id;
        if (id) {
            let tenantId = props.chartEditor.currentTenant;
            props.getPreviewData({ id: id, tenantId: tenantId });
        }
        let tenantId = props.chartEditor.currentTenant;
        props.getSources({ tenantId: tenantId });
    }, []);

    const handleBackButton = () => {
        props.setChartPreview({});
        props.setCurrentChart({});
        props.setPreviewData({});
        history.push("/charteditor");
    };
    const handleJSError = (value) => {
        setJSError(value);
    };
    return (
        <section className={classes.container}>
            <div className={classes.left}>
                <Grid container alignItems="flex-start" spacing={0}>
                    <Grid item xs={2}>
                        <Typography className={classes.label} variant="h6">
                            Type
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <FormControl component="fieldset">
                            <M_RadiosGroup
                                areaLabel=" Type"
                                name="type"
                                defaultValue={formData.type}
                                radios={type}
                                onChange={handleChartType}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography className={classes.label} variant="h6">
                            Name<span className="requiredMark">*</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <A_Textbox
                            variant="outlined"
                            size="medium"
                            id="chartName"
                            fullWidth={true}
                            helperText=""
                            defaultValue={formData.chartName}
                            error={
                                validationSummary &&
                                validationSummary.chartName &&
                                validationSummary.chartName.error
                                    ? validationSummary.chartName.error
                                    : null
                            }
                            helperText={
                                validationSummary &&
                                validationSummary.chartName &&
                                validationSummary.chartName.msg
                                    ? validationSummary.chartName.msg
                                    : null
                            }
                            onChange={handleFormData}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Typography className={classes.label} variant="h6">
                            Description
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <A_Textbox
                            variant="outlined"
                            size="medium"
                            id="description"
                            fullWidth={true}
                            helperText=""
                            multiline={true}
                            rows={3}
                            defaultValue={formData.description}
                            onChange={handleFormData}
                        />
                    </Grid>
                </Grid>
            </div>

            <div className={classes.right} style={{ marginRight: "15px" }}>
                <A_Button
                    color="primary"
                    size="medium"
                    label="Back"
                    onClick={handleBackButton}
                    disabled={props.user ? false : true}
                />
                &nbsp;&nbsp;
                <A_Button
                    color="primary"
                    size="medium"
                    label="Save"
                    disabled={props.user ? saving : true}
                    endIcon={
                        saving ? (
                            <A_CircularIndeternment
                                color="secondary"
                                size={20}
                            />
                        ) : (
                            ""
                        )
                    }
                    onClick={handleSave}
                />
            </div>
            <A_Snackbar
                open={changesSaved.status}
                message={changesSaved.msg}
                type={changesSaved.type}
                autoHideDuration={5000}
                horizontal="center"
                handleClose={handleSnackbarClose}
            />
            <A_SimpleDialog
                style={{ margin: "auto" }}
                open={openDialog.status}
                title="Error"
                dialogContent={openDialog.msg}
                dialogActions={
                    <A_Button
                        onClick={handleSimpleDialogClose}
                        label="close"
                        color="secondary"
                        size="small"
                    />
                }
            ></A_SimpleDialog>
            <Grid container>
                <Grid item>
                    <Typography className={classes.label} variant="h5">
                        Request
                    </Typography>
                </Grid>
                <Grid style={{ marginLeft: "42%", padding: "10px" }}>
                    <Typography className={classes.label} variant="h5">
                        Result
                    </Typography>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={5}>
                    <ChartEditorMain
                        data={defaultFormData}
                        getJavascriptConfig={handleJavascriptConfig}
                        getConfigurationEditor={handleConfigurationEditor}
                        OnJSError={handleJSError}
                    />
                </Grid>

                <Grid item xs={5} style={{ marginLeft: "100px" }}>
                    <ChartEditorResult />
                </Grid>
            </Grid>
        </section>
    );
};

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        tenants: state.tenants.data,
        chartEditor: state.chartEditor,
        currentChart: state.currentChart,
        chartPreview: state.chartPreview,
        PreviewData: state.PreviewData,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign(
            {},
            chartEditorActions,
            currentChartActions,
            chartPreviewActions
        ),
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChartConfigurationForm);
