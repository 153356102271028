import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import A_Textbox from "../../../../../../Atoms/Inputs/TextFields/A_TextField";
import M_RadiosGroup from "../../../../../../Molecules/Common/M_RadiosGroup";
import A_Select from "../../../../../../Atoms/Inputs/Selects/A_Select";
import A_Checkbox from "../../../../../../Atoms/Inputs/Checkboxes/A_Checkbox";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import A_Snackbar from "../../../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { isRequired } from "../../../../../../../../Utils/Helpers/validations";
import { templateTypes } from "../../../../../../../Utils/json/templateTypes";
import { dropdownHelpArticles } from "../../../../../../../Utils/json/dropdownHelpArticles";
import {
    templateType,
    templateExcessGroupType,
} from "../../../../../../../Utils/common/config/config";
import { message } from "../../../../../../../Utils/common/messages/message";
import { templateGroupType } from "../../../../../../../Utils/common/config/config";
const useStyles = makeStyles((theme) => ({
    container: {
        overflow: "auto",
        "& .MuiFormControl-root": {
            margin: theme.spacing(1),
            width: 440,
        },
        labelSectionHeader: {
            paddingTop: "10px",
        },
    },
}));

const MenuProps = {
    PaperProps: {
        style: {
            marginTop: "40px",
        },
    },
    variant: "menu",
    getContentAnchorEl: null,
};

let customController = true;

const GeneralDetails = (props) => {
    const [showResults, setShowResults] = React.useState(customController);
    const defaultFormData = {
        id: 1,
        name: "",
        description: "",
        templateType: "",
        status: "",
        helpArticle: "",
        sectionHeader: "",
        isRestricted: false,
        templateSecurity: {
            read: {
                groupids: [""],
                users: [],
            },
            update: {
                groupids: [""],
                users: [],
            },
            full: {
                groupids: [""],
                users: [],
            },
        },
    };
    const accessGroups =
        props.accessGroups.data &&
        typeof props.accessGroups.data !== templateGroupType.STRING
            ? props.accessGroups.data
            : [];
    const [formData, setFormData] = useState(
        props.data ? props.data : defaultFormData
    );
    const [changesSaved, setChangesSaved] = useState(false);
    const [templateNameValidation, setTemplateNameValidation] = useState({
        error: false,
    });
    const handleFormData = (e) => {
        let field = e.target.id;
        let newFormData = formData;
        newFormData[field] = e.target.value;
        setFormData(newFormData);
        props.getFormData(newFormData);
    };
    const handleTemplateType = (selectedRadio) => {
        props.callback(selectedRadio);
        let newFormData = formData;
        if (selectedRadio.id == "customController") {
            setShowResults(customController);
            props.onCustomControllerChange(false);
        } else {
            setShowResults(customController);
            props.onCustomControllerChange(true);
        }

        newFormData.templateType = selectedRadio.value;
        if (newFormData.templateType != templateType.FORM) {
            newFormData.isRestricted = false;
        }
        setFormData({ ...newFormData });
        props.getFormData(newFormData);
    };
    const handleHelpArticle = (selectedOption) => {
        let newFormData = formData;
        newFormData.helpArticle = selectedOption.value;
        setFormData(newFormData);
    };
    const GroupIdForGroup = function (groupNames) {
        let groupIdArr = [];
        groupNames.map((item) => {
            accessGroups.filter((group) => {
                if (group.name == item) {
                    groupIdArr.push(group.id.toString());
                }
            });
        });
        return groupIdArr;
    };
    const handleReadAccess = (event) => {
        let newFormData = { ...formData };
        if (newFormData.templateSecurity) {
        } else {
            newFormData.templateSecurity = {
                read: {
                    groupids: [""],
                    users: [],
                },
                update: {
                    groupids: [""],
                    users: [],
                },
                full: {
                    groupids: [""],
                    users: [],
                },
            };
        }
        newFormData.templateSecurity.read.users = event.target.value;
        newFormData.templateSecurity.read.groupids = GroupIdForGroup(
            event.target.value
        );
        setFormData(newFormData);
        props.getFormData(newFormData);
    };

    const handleDeleteReadAccess = (chipToDelete) => () => {
        let dataFilter = formData.templateSecurity.read.users;
        let deleteRead = dataFilter.filter((chip, index) => {
            return index !== chipToDelete;
        });
        let newFormData = { ...formData };
        newFormData.templateSecurity.read.users = deleteRead;
        newFormData.templateSecurity.read.groupids =
            GroupIdForGroup(deleteRead);
        setFormData(newFormData);
        props.getFormData(newFormData);
    };
    const handleValidations = () => {
        setTemplateNameValidation({ error: false, msg: "" });

        //Field Name
        let isValidated = true;
        if (!isRequired(formData.name)) {
            isValidated = false;
            setTemplateNameValidation({
                error: true,
                msg: message.REQUIRED_FIELD,
            });
        }

        return isValidated;
    };
    const handleSnackbarClose = () => {
        setChangesSaved(false);
    };
    const handleIsRestricted = (e) => {
        let newFormData = formData;
        newFormData["isRestricted"] = e.target.checked;
        setFormData({ ...newFormData });
        props.getFormData({ ...newFormData });
    };
    React.useEffect(() => {
        setFormData(props.data);
    }, [props.data]);

    const classes = useStyles();
    return (
        <section className={classes.container}>
            <Grid container alignItems="flex-start" spacing={0}>
                <Grid item xs={3}>
                    <Typography className={classes.label} variant="h6">
                        Template Type <span className="requiredMark">*</span>
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <FormControl component="fieldset">
                        <M_RadiosGroup
                            areaLabel="Template Type"
                            name="templateType"
                            defaultValue={formData.templateType}
                            radios={templateTypes}
                            onChange={handleTemplateType}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label} variant="h6">
                        Name <span className="requiredMark">*</span>
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <A_Textbox
                        variant="outlined"
                        size="medium"
                        id="name"
                        fullWidth={true}
                        defaultValue={formData.name}
                        error={props.validationSummary.name.error}
                        helperText={props.validationSummary.name.msg}
                        onChange={handleFormData}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label} variant="h6">
                        Description
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <A_Textbox
                        variant="outlined"
                        size="medium"
                        id="description"
                        fullWidth={true}
                        helperText=""
                        multiline={true}
                        rows={3}
                        defaultValue={formData.description}
                        onChange={handleFormData}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label} variant="h6">
                        Help Article
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <A_Select
                        required={true}
                        defaultValue={formData.helpArticle}
                        id="helpArticle"
                        helperText=""
                        error={false}
                        options={dropdownHelpArticles}
                        onChange={handleHelpArticle}
                    />
                </Grid>

                <Grid item xs={3}>
                    <Typography
                        className={classes.labelSectionHeader}
                        variant="h6"
                    >
                        Section Header
                    </Typography>
                </Grid>

                <Grid item xs={9}>
                    <A_Textbox
                        variant="outlined"
                        size="medium"
                        id="sectionHeader"
                        fullWidth={true}
                        helperText=""
                        defaultValue={formData.sectionHeader}
                        onChange={handleFormData}
                    />
                </Grid>

                {formData.templateType == templateType.VIEW ||
                formData.templateType == templateType.FORM ? (
                    <>
                        <Grid item xs={3}>
                            <Typography className={classes.label} variant="h6">
                                Permissions
                            </Typography>
                        </Grid>
                        <Grid>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-mutiple-chip-label">
                                    Read Access
                                </InputLabel>
                                <Select
                                    labelId="demo-mutiple-chip-label"
                                    id="demo-mutiple-chip"
                                    multiple
                                    variant="outlined"
                                    value={
                                        formData &&
                                        formData.templateSecurity &&
                                        formData.templateSecurity.read
                                            ? formData.templateSecurity.read
                                                  .users
                                            : []
                                    }
                                    onChange={handleReadAccess}
                                    input={<Input id="select-multiple-chip" />}
                                    renderValue={(e) => (
                                        <div className={classes.chips}>
                                            {e.map((item, value) => (
                                                <Chip
                                                    key={value}
                                                    label={item}
                                                    onDelete={handleDeleteReadAccess(
                                                        value
                                                    )}
                                                    onMouseDown={(event) => {
                                                        event.stopPropagation();
                                                    }}
                                                />
                                            ))}
                                        </div>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {accessGroups.map((item, index) => (
                                        <MenuItem key={index} value={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </>
                ) : (
                    ""
                )}
            </Grid>
            <A_Snackbar
                open={changesSaved}
                message={message.SAVED_CRM_DATA}
                autoHideDuration={5000}
                horizontal="center"
                handleClose={handleSnackbarClose}
            />
        </section>
    );
};
function mapStateToProps(state) {
    return {
        currentPage: state.currentPage,
        pages: state.pages,
        accessGroups: state.accessGroups,
    };
}

export default connect(mapStateToProps, null)(GeneralDetails);
