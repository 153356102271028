import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../../Pages/PageEditor/MainPage/Header/Header";
import * as tenantsActions from "../../../../store/actions/tenantsActions";
import * as currentSourcetActions from "../../../../store/actions/currentSourceAction";
import * as pageEditorActions from "../../../../store/actions/pageEditorActions";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
import * as sourceEditorAction from "../../../../store/actions/sourceEditorAction";
import { makeStyles } from "@material-ui/core/styles";
import MenuEditorForm from "./MenuEditorForm";
import { addID, delID, getMenu, saveMenu } from "./DataEditorHelper";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import A_Snackbar from "../../Atoms/Feedback/Snackbars/A_Snackbar";

const useStyles = makeStyles((theme) => ({
    maindiv: {
        overflow: "hidden",
    },
    buttonDiv: {
        display: "flex",
        justifyContent: "flex-end",
        margin: "0px 40px 10px 0px",
    },
    spanButton: {
        marginLeft: "10px",
    },
}));

const SourceEditor = (props) => {
    const classes = useStyles();
    const [allMenu, SetAllMenu] = React.useState([]);
    let changedJSon;
    var Currentstatus = false;
    const [changesSaved, setChangesSaved] = React.useState({
        status: false,
        type: "error",
        msg: "",
    });
    const [currentTenant, setCurrentTenant] = React.useState(
        props.sourceEditor.currentTenant ? props.sourceEditor.currentTenant : ""
    );
    const [currentTenantName, setCurrentTenantName] = React.useState("");
    const [toastStatus, setToastStatus] = React.useState(true);
    var UserSecurityGroups = props.groups.data;

    const tenantChangeHandler = async (e) => {
        let param = e;
        let currTenant = props.tenants.filter((tenant) => {
            return tenant.tenantId == param;
        })[0];
        setCurrentTenantName(currTenant.tenantName);
        props.getSource(e);
        setCurrentTenant(e);
    };

    React.useLayoutEffect(() => {
        props.getTenants();
        getAccessGroups(currentTenant);
        if (props.sourceEditor && props.sourceEditor.currentTenant) {
            props.getSource(props.sourceEditor.currentTenant);
        }
    }, []);

    React.useLayoutEffect(() => {
        getJson(currentTenant);
        getAccessGroups(currentTenant);
    }, [currentTenant]);

    const getAccessGroups = async (currentTenant) => {
        props.getAccessGroups(currentTenant);
    };

    const getJson = async () => {
        let id = currentTenant;
        let url = `${process.env.API_URL}/menuItems?tenantId=${id}`;
        const result = await getMenu(url);
        var newRes = addID(result);
        SetAllMenu(newRes);
    };
    const FinalJson = (array, status) => {
        if (status != undefined) {
            Currentstatus = status;
        }
        changedJSon = array;
    };
    const FinalSave = async () => {
        setToastStatus(true);
        if (!Currentstatus) {
            if (changedJSon != undefined) {
                let array = [...changedJSon];
                let data = delID(array);
                var uiMenu = {
                    menu: data,
                };
                let id = currentTenant;
                let url = `${process.env.API_URL}/menuItems/SaveMenuToCosmos?tenantId=${id}`;
                const result = await saveMenu(url, uiMenu);
                setChangesSaved({
                    status: true,
                    msg: "Saved Successfully",
                    type: "success",
                });
                var newRes = addID(changedJSon);
                SetAllMenu(newRes);
            } else {
                setChangesSaved({
                    status: true,
                    msg: "Nothing Changed",
                    type: "error",
                });
            }
        } else {
            setChangesSaved({
                status: true,
                msg: "Menu Name is Empty",
                type: "error",
            });
        }
    };
    const handleSnackbarClose = () => {
        setToastStatus(false);
        setChangesSaved({ status: false, msg: "" });
    };
    const cancel = () => {
        getJson();
        setToastStatus(true);
        setChangesSaved({
            status: true,
            msg:
                changedJSon == undefined || changedJSon == null || changedJSon == []
                    ? "Nothing Changed"
                    : "Canceled Successfully",
            type: "error",
        });
    };
    const variants = ["h2", "h2", "h2", "h2", "h2", "h2", "h2"];
    return (
        <div className={classes.maindiv}>
            <Header
                id="toolbar"
                title="Menu Editor"
                TenantData={props.tenants}
                value={currentTenant}
                isdisabledFlag={false}
                tenantChangeHandlerCallback={(e, name) =>
                    tenantChangeHandler(e, name)
                }
            />
            <>
                {allMenu != "" ? (
                    <div>
                        <MenuEditorForm
                            JSONData={allMenu}
                            FinalJson={FinalJson}
                            UserSecurityGroups={
                                UserSecurityGroups != undefined &&
                                UserSecurityGroups !=
                                    "No user groups exists for tenant."
                                    ? UserSecurityGroups
                                    : ""
                            }
                        />
                    </div>
                ) : (
                    <div>
                        {variants.map((variant) => (
                            <Typography
                                component="div"
                                key={variant}
                                variant={variant}
                            >
                                <Skeleton />
                            </Typography>
                        ))}
                    </div>
                )}
                <div className={classes.buttonDiv}>
                    <A_Button
                        label="Cancel"
                        color="primary"
                        size="medium"
                        onClick={() => cancel()}
                    ></A_Button>
                    <span className={classes.spanButton}>
                        <A_Button
                            label="Save"
                            color="primary"
                            size="medium"
                            onClick={() => FinalSave()}
                        ></A_Button>
                    </span>
                </div>
            </>
            {toastStatus && (
                <A_Snackbar
                    type={changesSaved.type}
                    open={changesSaved.status}
                    message={changesSaved.msg}
                    autoHideDuration={3000}
                    horizontal="center"
                    handleClose={handleSnackbarClose}
                />
            )}
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        tenants: state.tenants.data,
        sourceEditor: state.sourceEditor,
        currentSource: state.currentSource,
        pages: state.pages,
        groups: state.accessGroups,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign(
            {},
            tenantsActions,
            sourceEditorAction,
            currentSourcetActions,
            pageEditorActions
        ),
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SourceEditor);
