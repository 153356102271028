import React, { useEffect } from "react";
import { TreeView } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, FormHelperText } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import A_TextField from "../../Atoms/Inputs/TextFields/A_TextField";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
import { v4 as uuidv4 } from "uuid";
import A_MultiselectCustom from "../../Atoms/Inputs/MultiselectCustom/A_MultiselectCustom";

import {
    addNewChild,
    delChild,
    getID,
    EditMyJSON,
    StyledTreeItem,
    GethandleTabsSeletion,
    MinusSquare,
    PlusSquare,
    CloseSquare,
    checkValidName,
} from "./DataEditorHelper";
import A_SelectIcons from "../../Atoms/Inputs/Selects/A_SelectIcons";
import { menuIconsData } from "./menuIconsData";
import { object } from "prop-types";
const useStyles = makeStyles((theme) => ({
    root: {
        height: "auto",
        flexGrow: 1,
        maxWidth: 400,
    },
    MainMenu: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        minHeight: "72vh",
        marginTop: 10,
    },
    subRoot: {
        width: "1500px",
        padding: "0px 0px 0px 10px",
    },
    htmldata: {
        width: "10px",
        marginLeft: "38px",
    },
    addNew: {
        width: "10px",
        marginLeft: "20px",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    A_Button: {
        margin: "10px 0px 0px 100px",
    },
    form: {
        margin: "20px 0px 0px 70px",
    },
    delIcon: {
        color: "#1976d2",
        position: "relative",
        margin: "15px",
        left: "60px",
        cursor: "pointer",
    },
    add: {
        top: "-6px",
        position: "relative",
        margin: "15px",
        left: "80px",
        cursor: "pointer",
    },
    addICon: {
        width: "100%",
        textAlign: "left",
    },
    AddCircleOutlineIcon: {
        color: "#1976d2",
        position: "relative",
        margin: "-35px",
        left: "270px",
        cursor: "pointer",
    },
    DeleteIconSpan: {
        color: "#1976d2",
        position: "absolute",
        right: "200px",
        cursor: "pointer",
    },
    mainGrid: {
        padding: "10 0 10 100",
        alignItems: "center",
        border: "2px solid rgba(224, 224, 224, 1)",
        margin: "0px",
    },
    boldFont: {
        fontWeight: "bold",
    },
    textColor: {
        color: "red",
    },
    menuEditorSelectIcon: {
        marginTop: "8px",
        marginBottom: "4px",
        "& .MuiSelect-select": {
            display: "flex",
            paddingTop: "10px",
            paddingBottom: "11px",
            width: "174px",
            position: "relative",
        },
        "& .MuiListItemIcon-root": {
            position: "absolute",
            top: "8px",
            left: "16px",
        },
        "& .MuiTypography-root": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            paddingLeft: "34px",
        },
    },
}));
const MenuEditorForm = ({ JSONData, FinalJson, UserSecurityGroups }) => {
    const classes = useStyles();
    const [allMenu, SetAllMenu] = React.useState(JSONData);
    const [menuNameErrorMsg, SetMenuNameErrorMsg] = React.useState(false);
    const [menuNameError, SetMenuNameError] = React.useState("");
    const [SecurityError, SetSecurityError] = React.useState("");
    const [value, SetValue] = React.useState([]);
    const [iconValue, setIconValue] = React.useState("");
    const menuInput = React.useRef(null);
    const urlInput = React.useRef(null);
    const [formData, setFormData] = React.useState({
        primaryText: "",
        icon: "",
        url: "",
        permissionGroups: "",
        items: [],
        id: uuidv4(),
    });

    var menuIcons =
        menuIconsData != ""
            ? menuIconsData.map((items) => {
                  let label = items.label;
                  let value = items.value;
                  let icons = items.icon;
                  return {
                      value: value,
                      label: label,
                      icons: icons,
                  };
              })
            : [];

    var values =
        UserSecurityGroups != ""
            ? UserSecurityGroups.map((subItems) => {
                  let id = subItems.id;
                  let label = subItems.name;
                  return { Id: id, label: label, value: label };
              })
            : [];

    useEffect(() => {
        SetAllMenu(JSONData);
    }, [JSONData]);
    const deleteChild = (id, data) => {
        let returnedData = delChild(id, data);
        SetAllMenu(returnedData);
        FinalJson(returnedData);
    };
    const addNewChilds = (id, data) => {
        let returnedData = addNewChild(id, data);
        SetAllMenu(returnedData);
        let status = checkValidName(returnedData);
        if (status) {
            FinalJson(returnedData, true);
        } else {
            FinalJson(returnedData, false);
        }
        FinalJson(returnedData);
    };
    const MenuEdit = (event, id, label) => {
        var inputValue;
        if (label == "icon") {
            inputValue = JSON.stringify(event);
        } else {
            inputValue = event.target.value;
        }
        let array = [...allMenu];
        let returnedData = EditMyJSON(array, id, label, inputValue);
        let status = checkValidName(returnedData);
        if (status) {
            FinalJson(returnedData, true);
        } else {
            FinalJson(returnedData, false);
        }
    };
    const handleTabsSeletion = (value, id) => {
        var prevArray = values;
        var newArray = [];
        value.map((item1) => {
            let minValue = [];
            prevArray.map((item2) => {
                if (item1 === item2.label.toString()) {
                    minValue.push(item2.Id.toString());
                }
            });
            if (minValue.length === 0) {
                newArray.push(item1);
            } else {
                newArray.push(...minValue);
            }
        });
        var data = GethandleTabsSeletion(newArray, id, allMenu);
        FinalJson(data);
    };
    const getDefValues = (data) => {
        var prevArray = values;
        var newArrays = [];
        if (data != null && data != "") {
            data.map((item1) => {
                let minValue = [];
                prevArray.map((item2) => {
                    if (item1 === item2.Id.toString()) {
                        minValue.push(item2.label.toString());
                    }
                });
                if (minValue.length === 0) {
                    newArrays.push(item1);
                } else {
                    newArrays.push(...minValue);
                }
            });
        }
        return newArrays;
    };
    const getParseData = (data) => {
        try {
            var objectData = JSON.parse(data);
            return objectData && objectData.value ? objectData.value : "";
        } catch (e) {
            return data;
        }
    };
    const getTreeItemsFromData = (treeItems) => {
        const classes = useStyles();
        return treeItems.map((treeItemData) => {
            let items = undefined;
            if (treeItemData.items && treeItemData.items.length > 0) {
                items = getTreeItemsFromData(treeItemData.items);
            }
            return (
                <container style={{ display: "flex" }}>
                    <container key={treeItemData.id}>
                        <StyledTreeItem
                            key={treeItemData.id}
                            className={classes.subRoot}
                            nodeId={treeItemData.id}
                            label={
                                <Grid container draggable="true">
                                    <Grid item xs={2}>
                                        <container className={classes.htmldata}>
                                            <A_TextField
                                                label="Menu Name"
                                                variant="outlined"
                                                defaultValue={
                                                    treeItemData.primaryText
                                                }
                                                onChange={(event) => {
                                                    MenuEdit(
                                                        event,
                                                        treeItemData.id,
                                                        "primaryText"
                                                    );
                                                }}
                                            />
                                        </container>
                                        <div>
                                            <div key={treeItemData.primaryText}>
                                                <div
                                                    className={classes.addICon}
                                                >
                                                    <AddCircleOutlineIcon
                                                        className={
                                                            classes.AddCircleOutlineIcon
                                                        }
                                                        onClick={() =>
                                                            addNewChilds(
                                                                treeItemData.id,
                                                                allMenu
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <container className={classes.htmldata}>
                                            <A_TextField
                                                label="URL"
                                                variant="outlined"
                                                defaultValue={treeItemData.url}
                                                onChange={(event) => {
                                                    MenuEdit(
                                                        event,
                                                        treeItemData.id,
                                                        "url"
                                                    );
                                                }}
                                            />
                                        </container>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <container className={classes.htmldata}>
                                            <A_SelectIcons
                                                defaultValue={getParseData(
                                                    treeItemData.icon
                                                )}
                                                label="Icon"
                                                variant="outlined"
                                                options={menuIcons}
                                                id={treeItemData.id}
                                                className={
                                                    classes.menuEditorSelectIcon
                                                }
                                                onChange={(event) => {
                                                    MenuEdit(
                                                        event,
                                                        treeItemData.id,
                                                        "icon"
                                                    );
                                                }}
                                            />
                                        </container>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <container className={classes.htmldata}>
                                            <A_MultiselectCustom
                                                defaultValue={getDefValues(
                                                    treeItemData.permissionGroups
                                                )}
                                                label={"Security"}
                                                options={values}
                                                multiple={true}
                                                id={treeItemData.id}
                                                handleTabsSeletion={
                                                    handleTabsSeletion
                                                }
                                            />
                                        </container>
                                    </Grid>
                                </Grid>
                            }
                            children={items}
                            icons={treeItemData.icon}
                        ></StyledTreeItem>
                    </container>
                    <container className={classes.DeleteIconSpan}>
                        <DeleteIcon
                            className={classes.delIcon}
                            onClick={() =>
                                deleteChild(treeItemData.id, allMenu)
                            }
                        />
                    </container>
                </container>
            );
        });
    };
    const DataTreeView = ({ treeItems }) => {
        const classes = useStyles();
        return (
            <TreeView
                defaultCollapseIcon={<MinusSquare />}
                defaultExpandIcon={<PlusSquare />}
                defaultEndIcon={<CloseSquare />}
                className={classes.root}
                defaultExpanded={["1"]}
            >
                {getTreeItemsFromData(treeItems)}
            </TreeView>
        );
    };
    const OnChangeValue = (event, value) => {
        let newFormData = formData;
        newFormData["id"] = getID();
        if (value == "icon") {
            newFormData[value] = JSON.stringify(event);
            setIconValue(event.value);
        } else {
            newFormData[value] = event.target.value;
        }
        setFormData(newFormData);
    };
    const valid = (formData) => {
        if (
            formData.primaryText == null ||
            formData.primaryText == undefined ||
            formData.primaryText == ""
        ) {
            SetMenuNameError("Name is Required ");
            SetMenuNameErrorMsg(true);
        } else {
            SetMenuNameError("");
            SetMenuNameErrorMsg(false);
            return true;
        }
    };
    const onJsonChange = (data) => {
        let newMenu = [...allMenu];
        newMenu.push(data);
        return newMenu;
    };
    const TabsSeletion = (value, id) => {
        var prevArray = values;
        var newArray = [];
        value.map((item1) => {
            let minValue = [];
            prevArray.map((item2) => {
                if (item1 === item2.label.toString()) {
                    minValue.push(item2.Id.toString());
                }
            });
            if (minValue.length === 0) {
                newArray.push(item1);
            } else {
                newArray.push(...minValue);
            }
        });
        SetValue(value);
        let newFormData = formData;
        newFormData["id"] = getID();
        newFormData[id] = newArray;
        setFormData(newFormData);
    };
    const addParent = () => {
        if (valid(formData)) {
            let newJson = onJsonChange(formData);
            SetAllMenu(newJson);
            setIconValue(null);
            menuInput.current.value = "";
            urlInput.current.value = "";
            SetValue([]);

            setFormData({
                primaryText: "",
                icon: "",
                url: "",
                permissionGroups: "",
                items: [],
                id: "",
            });
            FinalJson(newJson);
        }
    };
    return (
        <div className={classes.MainMenu}>
            {allMenu.length > 0 ? <DataTreeView treeItems={allMenu} /> : ""}

            <container style={{ display: "flex" }}>
                <container>
                    <StyledTreeItem
                        className={classes.subRoot}
                        label={
                            <Grid container draggable="true">
                                <Grid item xs={2}>
                                    <container className={classes.htmldata}>
                                        <A_TextField
                                            error={menuNameErrorMsg}
                                            inputRef={menuInput}
                                            helperText={menuNameError}
                                            label="Add New Menu"
                                            variant="outlined"
                                            required={true}
                                            onChange={(event) => {
                                                OnChangeValue(
                                                    event,
                                                    "primaryText"
                                                );
                                            }}
                                        />
                                    </container>
                                </Grid>
                                <Grid item xs={2}>
                                    <container className={classes.htmldata}>
                                        <A_TextField
                                            inputRef={urlInput}
                                            label="URL"
                                            variant="outlined"
                                            onChange={(event) => {
                                                OnChangeValue(event, "url");
                                            }}
                                        />
                                    </container>
                                </Grid>
                                <Grid item xs={2}>
                                    <container className={classes.htmldata}>
                                        <A_SelectIcons
                                            defaultValue={
                                                iconValue ? iconValue : null
                                            }
                                            label="Icon"
                                            variant="outlined"
                                            className={
                                                classes.menuEditorSelectIcon
                                            }
                                            options={menuIcons}
                                            onChange={(event) => {
                                                OnChangeValue(event, "icon");
                                            }}
                                        />
                                    </container>
                                </Grid>
                                <Grid item xs={2}>
                                    <container className={classes.htmldata}>
                                        <A_MultiselectCustom
                                            defaultValue={value}
                                            label={"Security"}
                                            options={values}
                                            multiple={true}
                                            id={"permissionGroups"}
                                            handleTabsSeletion={TabsSeletion}
                                        />
                                        <FormHelperText
                                            className={classes.textColor}
                                        >
                                            {SecurityError}
                                        </FormHelperText>
                                    </container>
                                </Grid>
                            </Grid>
                        }
                    ></StyledTreeItem>
                </container>
                <container className={classes.DeleteIconSpan}>
                    <A_Button
                        className={classes.add}
                        label="add"
                        color="primary"
                        size="medium"
                        onClick={(event) => addParent()}
                    ></A_Button>
                </container>
            </container>
        </div>
    );
};
export default MenuEditorForm;
