import { take, put, select } from "redux-saga/effects";
import axios from "axios";
import { emailTemplateActionTypes } from "../actions/actionTypes";
import { attachmentKeyActionTypes } from "../actions/actionTypes";
import * as emailTemplateActions from "../actions/emailTemplateActions";
import * as currentEmailTemplateActions from "../actions/currentEmailTemplateActions";
import * as commonFunctions from "../../Utils/Helpers/common";
import * as attachmentKeyActions from "../actions/attachmentKeyActions";

export function* getEmailTemplateSaga() {
    while (true) {
        const action = yield take(emailTemplateActionTypes.GET_EMAIL_TEMPLATES);
        // const payload = action.payload;
        let config = commonFunctions.getHttpConfig();
        try {
            const res = yield axios.get(
                `${process.env.API_URL}/emailTemplates`,
                config
            );
            console.log("getEmailTemplateSaga response:", res);
            let convertedData = res.data === "Empty" ? [] : res.data;
            yield put(
                emailTemplateActions.getEmailTemplateSucceeded({
                    data: convertedData,
                })
            );
        } catch (e) {
            emailTemplateActions.getEmailTemplateFailed({
                msg: "Some error occured while getting email tempaltes.",
            });
            console.log("Some error occured while getting email tempaltes.");
            console.log(e.message);
        }
    }
}
export function* saveEmailTemplateSaga() {
    while (true) {
        const action = yield take(emailTemplateActionTypes.SAVE_EMAIL_TEMPLATE);
        const emailTemplateData = action.payload;
        let config = commonFunctions.getHttpConfig();
        if (emailTemplateData.id == 0) {
            let apiUrl = `${process.env.API_URL}/emailTemplate/insert`;
            try {
                const res = yield axios.post(apiUrl, emailTemplateData, config);
                console.log("saveEmailTemplateSaga response:", res);
                let convertedData = res.data === "Empty" ? [] : res.data;
                yield put(
                    currentEmailTemplateActions.saveEmailTemplateSucceeded({
                        ...convertedData,
                        emailTemplateSaved: true,
                    })
                );
                yield put(
                    emailTemplateActions.getEmailTemplates({
                        ...convertedData,
                        emailTemplateSaved: true,
                    })
                );
            } catch (e) {
                yield put(
                    currentEmailTemplateActions.saveEmailTemplateFailed({
                        ...emailTemplateData,
                        emailTemplateSaved: false,
                        saveEmailTemplateFailed: true,
                        errorMessage: e.response.data.message
                            ? e.response.data.message
                            : Object.values(e.response.data.errors)[0][0],
                    })
                );
            }
        } else {
            let apiUrl = `${process.env.API_URL}/emailTemplate/update`;
            try {
                const res = yield axios.put(apiUrl, emailTemplateData, config);
                console.log("ModifyEmailTemplateSaga response:", res);
                let convertedData = res.data === "Empty" ? [] : res.data;
                yield put(
                    currentEmailTemplateActions.saveEmailTemplateSucceeded({
                        ...convertedData,
                        emailTemplateSaved: true,
                    })
                );
                yield put(
                    emailTemplateActions.getEmailTemplates({
                        ...convertedData,
                        emailTemplateSaved: true,
                    })
                );
            } catch (e) {
                yield put(
                    currentEmailTemplateActions.saveEmailTemplateFailed({
                        ...emailTemplateData,
                        emailTemplateSaved: false,
                        saveEmailTemplateFailed: true,
                        errorMessage: e.response.data.message
                            ? e.response.data.message
                            : Object.values(e.response.data.errors)[0][0],
                    })
                );
            }
        }
    }
}
export function* deleteEmailTemplateSaga() {
    while (true) {
        const action = yield take(
            emailTemplateActionTypes.DELETE_EMAIL_TEMPLATE
        );
        const id = action.payload;
        let apiUrl = `${process.env.API_URL}/emailTemplate/delete/${id.id}`;

        let config = commonFunctions.getHttpConfig();

        try {
            const res = yield axios.delete(apiUrl, config, {});
            // const res = yield axios.delete(apiUrl, id.id, config);
            console.log("deleteEmailTemplateSaga response:", res);
            let convertedData = res.data === "Empty" ? [] : res.data;
            yield put(
                currentEmailTemplateActions.deleteEmailTemplateSucceeded({
                    ...convertedData,
                    emailTemplateDeleted: true,
                })
            );
        } catch (e) {
            yield put(
                currentEmailTemplateActions.deleteEmailTemplateFailed({
                    ...id,
                    errorMessage: e.response.data.message
                        ? e.response.data.message
                        : Object.values(e.response.data.errors)[0][0],
                    emailTemplateDeleted: false,
                })
            );
            console.log(e.message);
        }
    }
}
export function* getAttachmentKeySaga() {
    while (true) {
        const action = yield take(attachmentKeyActionTypes.GET_ATTACHMENT_KEYS);
        let config = commonFunctions.getHttpConfig();
        try {
            const res = yield axios.get(
                `${process.env.API_URL}/emailTemplates/attachmentkeys`,
                config
            );
            console.log("getAttachmentKeySaga response:", res);
            let convertedData = res.data === "Empty" ? [] : res.data;
            yield put(
                attachmentKeyActions.getAttachmentKeysSucceeded({
                    data: convertedData,
                })
            );
        } catch (e) {
            attachmentKeyActions.getAttachmentKeysFailed({
                msg: "Some error occured while getting email attachment keys.",
            });
            console.log(
                "Some error occured while getting email attachment keys."
            );
            console.log(e.message);
        }
    }
}
