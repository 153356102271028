import React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import A_Textbox from "../../../../Atoms/Inputs/TextFields/A_TextField";
import M_RadiosGroup from "../../../../Molecules/Common/M_RadiosGroup";
import A_Snackbar from "../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { GeneralDetailsIcons } from "./GeneralDetailsIcons";
import A_SelectIcons from "../../../../Atoms/Inputs/Selects/A_SelectIcons";

const useStyles = makeStyles((theme) => ({
    container: {
        overflow: "auto",
        "& .MuiFormControl-root": {
            margin: theme.spacing(1),
            width: 440,
        },
    },
    GenralDetailsSelectIcon: {
        "& .MuiSelect-select": {
            display: "flex",
            paddingTop: "10px",
            paddingBottom: "11px",
            position: "relative",
        },
        "& .MuiListItemIcon-root": {
            position: "absolute",
            top: "8px",
            left: "16px",
        },
        "& .MuiTypography-root": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            paddingLeft: "34px",
        },
    },
}));
const MenuProps = {
    PaperProps: {
        style: {
            marginTop: "40px",
        },
    },
    variant: "menu",
    getContentAnchorEl: null,
};
const GeneralDetails = (props) => {
    const pageTypes = [
        {
            id: "CRM",
            label: "CRM",
            value: "CRM",
        },
        {
            id: "Non-CRM",
            label: "Non-CRM",
            value: "Non-CRM",
        },
    ];
    var menuIcons =
        GeneralDetailsIcons != ""
            ? GeneralDetailsIcons.map((items) => {
                  let label = items.label;
                  let value = items.value;
                  let icons = items.icon;
                  return {
                      value: value,
                      label: label,
                      icons: icons,
                  };
              })
            : [];
    const accessGroups =
        props.accessGroups.data && typeof props.accessGroups.data !== "string"
            ? props.accessGroups.data
            : [];
    const [formData, setFormData] = useState(props.currentPage);
    const [changesSaved, setChangesSaved] = useState(false);

    useEffect(() => {
        setFormData(props.currentPage);
        return () => {
            setFormData({});
        };
    }, [props.currentPage]);

    const handleFormData = (e) => {
        let field = e.target.id;
        let newFormData = formData;
        newFormData[field] = e.target.value;
        setFormData(newFormData);
        props.getFormData(newFormData);
    };
    const handleIconData = (e) => {
        let field = "defaultImage";
        let newFormData = formData;
        newFormData[field] = e.value;
        setFormData(newFormData);
        props.getFormData(newFormData);
    };
    const handlePageType = (selectedRadio) => {
        let newFormData = formData;
        if (selectedRadio.id == "Non-CRM") {
            props.onPageTypeChange(false);
        } else {
            props.onPageTypeChange(true);
        }

        newFormData.type = selectedRadio.value;
        setFormData(newFormData);
        props.getFormData(newFormData);
        //debugger;
    };
    const GroupIdForGroup = function (groupNames) {
        let groupIdArr = [];
        groupNames.map((item) => {
            accessGroups.filter((group) => {
                if (group.name == item) {
                    groupIdArr.push(group.id.toString());
                }
            });
        });
        return groupIdArr;
    };
    const handleReadAccess = (event) => {
        let newFormData = { ...formData };
        if (newFormData.pageSecurity) {
        } else {
            newFormData.pageSecurity = {
                read: {
                    groupids: [""],
                    users: [],
                },
                update: {
                    groupids: [""],
                    users: [],
                },
                full: {
                    groupids: [""],
                    users: [],
                },
            };
        }
        newFormData.pageSecurity.read.users = event.target.value;
        newFormData.pageSecurity.read.groupids = GroupIdForGroup(
            event.target.value
        );
        setFormData(newFormData);
        props.getFormData(newFormData);
    };
    const handleDeleteReadAccess = (chipToDelete) => () => {
        let dataFilter = formData.pageSecurity.read.users;
        let deleteRead = dataFilter.filter((chip, index) => {
            return index !== chipToDelete;
        });
        let newFormData = { ...formData };
        newFormData.pageSecurity.read.users = deleteRead;
        newFormData.pageSecurity.read.groupids = GroupIdForGroup(deleteRead);

        props.getFormData(newFormData);
    };
    const handleEditAccess = (event) => {
        let newFormData = { ...formData };
        if (newFormData.pageSecurity) {
        } else {
            newFormData.pageSecurity = {
                read: {
                    groupids: [""],
                    users: [],
                },
                update: {
                    groupids: [""],
                    users: [],
                },
                full: {
                    groupids: [""],
                    users: [],
                },
            };
        }
        newFormData.pageSecurity.update.users = event.target.value;
        newFormData.pageSecurity.update.groupids = GroupIdForGroup(
            event.target.value
        );
        setFormData(newFormData);
        props.getFormData(newFormData);
    };
    const handleDeleteEditAccess = (chipToDelete) => () => {
        let dataFilter = formData.pageSecurity.update.users;
        let deleteEdit = dataFilter.filter((chip, index) => {
            return index !== chipToDelete;
        });
        let newFormData = { ...formData };
        formData.pageSecurity.update.users = deleteEdit;
        newFormData.pageSecurity.update.groupids = GroupIdForGroup(deleteEdit);

        setFormData(newFormData);
        props.getFormData(newFormData);
    };
    const handleFullAccess = (event) => {
        let newFormData = { ...formData };
        if (newFormData.pageSecurity) {
        } else {
            newFormData.pageSecurity = {
                read: {
                    groupids: [""],
                    users: [],
                },
                update: {
                    groupids: [""],
                    users: [],
                },
                full: {
                    groupids: [""],
                    users: [],
                },
            };
        }
        newFormData.pageSecurity.full.users = event.target.value;
        newFormData.pageSecurity.full.groupids = GroupIdForGroup(
            event.target.value
        );
        setFormData(newFormData);
        props.getFormData(newFormData);
    };
    const handleDeleteFullAccess = (chipToDelete) => () => {
        let dataFilter = formData.pageSecurity.full.users;
        let deleteFull = dataFilter.filter((chip, index) => {
            return index !== chipToDelete;
        });
        let newFormData = { ...formData };
        formData.pageSecurity.full.users = deleteFull;
        newFormData.pageSecurity.full.groupids = GroupIdForGroup(deleteFull);
        setFormData(newFormData);
        props.getFormData(newFormData);
    };

    const handleSnackbarClose = () => {
        setChangesSaved(false);
    };

    const classes = useStyles();
    return (
        <section className={classes.container}>
            <Grid container alignItems="flex-start" spacing={0}>
                <Grid item xs={3}>
                    <Typography className={classes.label} variant="h6">
                        Name <span className="requiredMark">*</span>
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <A_Textbox
                        variant="outlined"
                        size="medium"
                        id="name"
                        fullWidth={true}
                        defaultValue={formData.name}
                        error={props.validationSummary.name.error}
                        helperText={props.validationSummary.name.msg}
                        onChange={handleFormData}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label} variant="h6">
                        Type <span className="requiredMark">*</span>
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <FormControl component="fieldset">
                        <M_RadiosGroup
                            areaLabel="Page Type"
                            name="type"
                            defaultValue={formData.type}
                            radios={pageTypes}
                            onChange={handlePageType}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label} variant="h6">
                        URL <span className="requiredMark">*</span>
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <A_Textbox
                        variant="outlined"
                        size="medium"
                        id="url"
                        fullWidth={true}
                        defaultValue={formData.url}
                        onChange={handleFormData}
                        error={props.validationSummary.url.error}
                        helperText={props.validationSummary.url.msg}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label} variant="h6">
                        Description
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <A_Textbox
                        variant="outlined"
                        size="medium"
                        id="description"
                        fullWidth={true}
                        helperText=""
                        multiline={true}
                        rows={3}
                        defaultValue={formData.description}
                        onChange={handleFormData}
                    />
                </Grid>
                {
                    // commenting this for now and keeping for future refrence
                }
                {/* <Grid item xs={3}>
                    <Typography className={classes.label} variant="h6">
                        Access Groups
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <A_Textbox
                        variant="outlined"
                        size="medium"
                        id="accessGroup"
                        fullWidth={true}
                        helperText=""
                        multiline={true}
                        rows={3}
                        defaultValue={formData.accessGroup}
                        onChange={handleFormData}
                    />
                </Grid> */}
                <Grid item xs={3}>
                    <Typography className={classes.label} variant="h6">
                        Default Picture
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <A_SelectIcons
                        className={classes.GenralDetailsSelectIcon}
                        defaultValue={formData.defaultImage}
                        label=""
                        options={menuIcons}
                        onChange={handleIconData}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label} variant="h6">
                        Permissions
                    </Typography>
                </Grid>
                <Grid>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-mutiple-chip-label">
                            Read Access
                        </InputLabel>
                        <Select
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            multiple
                            variant="outlined"
                            value={
                                formData &&
                                formData.pageSecurity &&
                                formData.pageSecurity.read
                                    ? formData.pageSecurity.read.users
                                    : []
                            }
                            onChange={handleReadAccess}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(e) => (
                                <div className={classes.chips}>
                                    {e.map((item, value) => (
                                        <Chip
                                            key={value}
                                            label={item}
                                            onDelete={handleDeleteReadAccess(
                                                value
                                            )}
                                            onMouseDown={(event) => {
                                                event.stopPropagation();
                                            }}
                                        />
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}
                        >
                            {accessGroups.map((item, index) => (
                                <MenuItem key={index} value={item.name}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={9} style={{ position: "relative", left: "238px" }}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-mutiple-chip-label">
                            Edit Access
                        </InputLabel>
                        <Select
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            multiple
                            variant="outlined"
                            value={
                                formData &&
                                formData.pageSecurity &&
                                formData.pageSecurity.update
                                    ? formData.pageSecurity.update.users
                                    : []
                            }
                            onChange={handleEditAccess}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(e) => (
                                <div className={classes.chips}>
                                    {e.map((item, value) => (
                                        <Chip
                                            key={value}
                                            label={item}
                                            onDelete={handleDeleteEditAccess(
                                                value
                                            )}
                                            onMouseDown={(event) => {
                                                event.stopPropagation();
                                            }}
                                        />
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}
                        >
                            {accessGroups.map((item, index) => (
                                <MenuItem key={index} value={item.name}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={9} style={{ position: "relative", left: "238px" }}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-mutiple-chip-label">
                            Full Access
                        </InputLabel>
                        <Select
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            multiple
                            variant="outlined"
                            value={
                                formData &&
                                formData.pageSecurity &&
                                formData.pageSecurity.full
                                    ? formData.pageSecurity.full.users
                                    : []
                            }
                            onChange={handleFullAccess}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(e) => (
                                <div className={classes.chips}>
                                    {e.map((item, value) => (
                                        <Chip
                                            key={value}
                                            label={item}
                                            onDelete={handleDeleteFullAccess(
                                                value
                                            )}
                                            onMouseDown={(event) => {
                                                event.stopPropagation();
                                            }}
                                        />
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}
                        >
                            {accessGroups.map((item, index) => (
                                <MenuItem key={index} value={item.name}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <A_Snackbar
                open={changesSaved}
                message="Changes saved successfully"
                autoHideDuration={5000}
                horizontal="center"
                handleClose={handleSnackbarClose}
            />
        </section>
    );
};

function mapStateToProps(state) {
    return {
        currentPage: state.currentPage,
        pages: state.pages,
        accessGroups: state.accessGroups,
    };
}

export default connect(mapStateToProps, null)(GeneralDetails);
