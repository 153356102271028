import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import * as currentPageActions from "../../../../../../../store/actions/currentPageActions";
import A_Textbox from "../../../../../Atoms/Inputs/TextFields/A_TextField";
import A_Select from "../../../../../Atoms/Inputs/Selects/A_Select";
import A_Button from "../../../../../Atoms/Inputs/Buttons/A_Button";
import A_Snackbar from "../../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_Checkbox from "../../../../../Atoms/Inputs/Checkboxes/A_Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { formatTypes } from "./DateFormatTypes";
import { fieldTypes } from "./FieldDataTypes";
import { radioFields } from "./radioFields";

import {
    isRequired,
    isExists,
    isExistsMoreThan1Time,
} from "../../../../../../../Utils/Helpers/validations";
import * as commonFunctions from "../../../../../../../Utils/Helpers/common";
const useStyles = makeStyles((theme) => ({
    container: {
        overflow: "auto",
        "& .MuiFormControl-root": {
            margin: theme.spacing(1),
            width: 440,
        },
    },
    left: {
        float: "left",
        width: "80%",
    },
    right: {
        float: "right",
    },
    Checkbox: {
        paddingLeft: "6px",
    },
}));

const ManageFieldsForm = (props) => {
    let destinationVertexfield = "";
    const defaultFormData = props.data;

    const isAdd =
        props.data.id == undefined || props.data.id == 0 ? true : false;
    const [formData, setFormData] = useState(
        props.data ? props.data : defaultFormData
    );

    const [formatDate, setFormatDate] = useState(
        props.data.fieldType === "date" ? false : true
    );
    const [changesSaved, setChangesSaved] = useState(false);
    const [fieldNameValidation, setFieldNameValidation] = useState({
        error: false,
    });

    const [istitleValidation, setIsTitleValidation] = useState({
        error: false,
    });
    const [fieldTypeValidation, setFieldTypeValidation] = useState({
        error: false,
    });
    const [edgeLabelValidation, setEdgeLabelValidation] = useState({
        error: false,
    });
    const [destinationVertexValidation, setDestinationVertexValidation] =
        useState({ error: false });
    const [isInverseValidation, setIsInverseValidation] = useState({
        error: false,
    });

    const [resultTemplateValidation, setResultTemplateValidation] = useState({
        error: false,
    });
    const [optionSetValidation, setOptionSetValidation] = useState({
        error: false,
    });
    const [alignValidation, setAlignValidation] = useState({
        error: false,
    });
    const [radioValidation, setRadioValidation] = useState({
        error: false,
    });
    const [MultiSelectValidation, setMultiSelectValidation] = useState({
        error: false,
    });
    const [isInverseOption, setIsInverseOption] = useState([
        { label: "Yes", value: "True" },
        { label: "No", value: "False" },
    ]);
    const [validations, setValidations] = useState([]);
    const [destinationVertices, setDestinationVertices] = useState([]);
    if (formData.id !== 0) {
        if (destinationVertices !== undefined) {
            let arr = destinationVertices.filter(
                (item) => props.data.destinationVertex == item.url
            );
            if (arr.length > 0) {
                let name = arr[0].name;
                destinationVertexfield = name;
            }
        }
    }

    if (destinationVertexfield === "") {
        destinationVertexfield = formData.destinationVertex;
    }

    const [resultTemplate, setResultTemplate] = useState([]);
    const [optionSetDisabled, setOptionSetDisabled] = useState(
        formData.fieldType == "optionset" ? false : true
    );

    const [alignDisabled, setAlignDisabled] = useState(
        formData.fieldType == "radios" ? false : true
    );
    const [destinationVerticesId, setDestinationVerticesId] = useState("");

    const [destinationVerticesDisabled, setDestinationVerticesDisabled] =
        useState(formData.fieldType == "lookup" ? false : true);

    const [resultTemplateDisabled, setResultTemplateDisabled] = useState(
        formData.fieldType == "lookup" ? false : true
    );
    const optionSets = commonFunctions.convertToLabelValueFormat(
        "id",
        "optionSetName",
        props.currentPage.optionSets,
        true
    );

    React.useEffect(() => {
        if (props.data && props.data.destinationVertex) {
            let data = props.destinationVertices
                ? props.destinationVertices.filter(
                      (vertices) =>
                          vertices.url === props.data.destinationVertex
                  )
                : [];
            if (data.length > 0) {
                let vertex = data[0];
                props.getResultTemplate({
                    tenantId: props.tenantId,
                    pageId: vertex.id ? vertex.id : "",
                });
            }
        }
    }, []);
    React.useEffect(() => {
        props.getValidations(props.currentPage);
        props.getDestinationVertices({ tenantId: props.tenantId });
    }, []);

    React.useEffect(() => {
        props.getValidations(props.currentPage);
        props.getResultTemplate({
            tenantId: props.tenantId,
            pageId: destinationVerticesId,
        });
    }, []);

    React.useEffect(() => {
        setValidations(props.fieldValidations);
    }, [props.fieldValidations]);

    React.useEffect(() => {
        setDestinationVertices(props.destinationVertices);
    }, []);

    React.useEffect(() => {
        setResultTemplate(props.resultTemplate);
    }, [props.resultTemplate]);

    React.useEffect(() => {
        if (formData.fieldType == "optionset") {
            setOptionSetDisabled(
                formData.fieldType == "optionset" ? false : true
            );
            return;
        }
        if (formData.fieldType == "radios") {
            setOptionSetDisabled(formData.fieldType == "radios" ? false : true);
            return;
        }
        if (formData.fieldType == "multiselect") {
            setOptionSetDisabled(
                formData.fieldType == "multiselect" ? false : true
            );
            return;
        }
        setOptionSetDisabled(true);
    }, [optionSetDisabled]);

    React.useEffect(() => {
        setAlignDisabled(formData.fieldType == "radios" ? false : true);
    }, [alignDisabled]);

    React.useEffect(() => {
        setDestinationVerticesDisabled(
            formData.fieldType == "lookup" ? false : true
        );
    }, [destinationVerticesDisabled]);

    React.useEffect(() => {
        setResultTemplateDisabled(
            formData.fieldType == "lookup" ? false : true
        );
    }, [resultTemplateDisabled]);
    const handleFormData = (e) => {
        let field = e.target.id;
        let newFormData = formData;

        newFormData[field] =
            e.target.value !== undefined && e.target.value.length > 0
                ? e.target.value.trim()
                : e.target.value;

        setFormData(newFormData);
    };

    const handlelettersAllowed = (lettersAllowed) => {
        formData.hasOwnProperty("lettersAllowed")
            ? (formData.lettersAllowed = lettersAllowed.target.checked)
            : "";
        setFormData({ ...formData });
    };

    const handleTitleCheckbox = (titleCheckbox) => {
        //let newFormData = formData;
        formData.isPageTitle = titleCheckbox.target.checked;
        setFormData({ ...formData });
    };
    const handleSearchableCheckbox = (searchCheckbox) => {
        formData.isSearchable = searchCheckbox.target.checked;
        setFormData({ ...formData });
    };
    const handleCurrentTabCheckbox = (currentTabCheckbox) => {
        formData.inNewTab = currentTabCheckbox.target.checked;
        setFormData({ ...formData });
    };
    const handleLinkOpenCheckbox = (linkOpenCheckbox) => {
        formData.inLink = linkOpenCheckbox.target.checked;
        setFormData({ ...formData });
    };
    const handleDropdowns = (selectedOption, id) => {
        let newFormData = {};

        switch (id.toLowerCase()) {
            case "formattype":
                newFormData = formData;
                newFormData[id] = selectedOption.value;
                break;
            case "align":
                newFormData = formData;
                newFormData[id] = selectedOption.value;
                break;
            case "destinationvertex":
                setDestinationVerticesId(selectedOption.id);
                props.getResultTemplate({
                    tenantId: props.tenantId,
                    pageId: selectedOption.id,
                });
                newFormData = formData;
                newFormData[id] = selectedOption.url;
                break;

            case "fieldtype":
                newFormData = formData;
                newFormData[id] = selectedOption.value;
                switch (selectedOption.value.toLowerCase()) {
                    case "date":
                        setFormatDate(false);
                        newFormData["edgeLabel"] = "";
                        newFormData["destinationVertex"] = "";
                        newFormData["optionSet"] = "";
                        break;
                    case "lookup":
                        setFormatDate(true);
                        newFormData["optionSet"] = "";
                        break;
                    case "optionset":
                        setFormatDate(true);
                        newFormData["edgeLabel"] = "";
                        newFormData["destinationVertex"] = "";
                        break;
                    case "radios":
                        setFormatDate(true);
                        newFormData["edgeLabel"] = "";
                        newFormData["destinationVertex"] = "";
                        break;
                    case "multiselect":
                        setFormatDate(true);
                        newFormData["edgeLabel"] = "";
                        newFormData["destinationVertex"] = "";
                        break;
                    default:
                        setFormatDate(true);
                        newFormData["edgeLabel"] = "";
                        newFormData["destinationVertex"] = "";
                        newFormData["optionSet"] = "";
                }
                if (formData.fieldType == "optionset") {
                    setOptionSetDisabled(
                        formData.fieldType == "optionset" ? false : true
                    );
                } else if (formData.fieldType == "radios") {
                    setOptionSetDisabled(
                        formData.fieldType == "radios" ? false : true
                    );
                } else if (formData.fieldType == "multiselect") {
                    setOptionSetDisabled(
                        formData.fieldType == "multiselect" ? false : true
                    );
                } else {
                    setOptionSetDisabled(!optionSetDisabled);
                }
                setAlignDisabled(formData.fieldType == "radios" ? false : true);
                setDestinationVerticesDisabled(
                    formData.fieldType == "lookup" ? false : true
                );
                setResultTemplateDisabled(
                    formData.fieldType == "lookup" ? false : true
                );

                break;
            case "isinverse":
                newFormData = formData;
                newFormData[id] = selectedOption.value;
                break;
            default:
                newFormData = formData;
                newFormData[id] = selectedOption.value;
        }
        setFormData(newFormData);
    };

    const handleValidations = () => {
        setFieldNameValidation({ error: false, msg: "" });
        setFieldTypeValidation({ error: false, msg: "" });
        setEdgeLabelValidation({ error: false, msg: "" });
        setDestinationVertexValidation({ error: false, msg: "" });
        setIsInverseValidation({ error: false, msg: "" });
        setResultTemplateValidation({ error: false, msg: "" });
        setOptionSetValidation({ error: false, msg: "" });
        setRadioValidation({ error: false, msg: "" });
        setMultiSelectValidation({ error: false, msg: "" });
        setAlignValidation({ error: false, msg: "" });

        //Field Name
        let isValidated = true;
        if (!isRequired(formData.name)) {
            isValidated = false;
            setFieldNameValidation({ error: true, msg: "Name is required" });
        }
        //check for duplicate field name
        if (isAdd) {
            let isDuplicate = isExists(
                formData.name,
                "name",
                props.currentPage.fields
            );
            if (isDuplicate) {
                isValidated = false;
                setFieldNameValidation({
                    error: true,
                    msg: "Name already exists.",
                });
            }
        } else {
            let isDuplicate = isExistsMoreThan1Time(
                formData.name,
                "name",
                props.currentPage.fields
            );
            if (isDuplicate) {
                isValidated = false;
                setFieldNameValidation({
                    error: true,
                    msg: "Name already exists.",
                });
            }
        }

        //Field Type
        if (!isRequired(formData.fieldType)) {
            isValidated = false;
            setFieldTypeValidation({
                error: true,
                msg: "Field type is required",
            });
        }

        //Edge label
        if (
            formData.fieldType === "lookup" &&
            !isRequired(formData.edgeLabel)
        ) {
            isValidated = false;
            setEdgeLabelValidation({
                error: true,
                msg: "Edge label is required",
            });
        }
        //Destination vertex
        if (
            formData.fieldType === "lookup" &&
            !isRequired(formData.destinationVertex)
        ) {
            isValidated = false;
            setDestinationVertexValidation({
                error: true,
                msg: "Destination vertex label is required",
            });
            setIsInverseValidation({
                error: true,
                msg: "IsInverse is required",
            });
        }
        //ResultTemplate
        if (
            formData.fieldType === "lookup" &&
            !isRequired(formData.resultTemplate)
        ) {
            isValidated = false;
            setResultTemplateValidation({
                error: true,
                msg: "ResultTemplate is required",
            });
        }

        //Optionsets
        if (
            formData.fieldType === "optionset" &&
            !isRequired(formData.optionSet)
        ) {
            isValidated = false;
            setOptionSetValidation({
                error: true,
                msg: "Optionset is required",
            });
        }
        //align
        if (formData.fieldType === "radios" && !isRequired(formData.align)) {
            isValidated = false;
            setAlignValidation({
                error: true,
                msg: "alignment is required",
            });
        }
        // MultiSelect
        if (
            formData.fieldType === "multiselect" &&
            !isRequired(formData.optionSet)
        ) {
            isValidated = false;
            setOptionSetValidation({
                error: true,
                msg: "Optionset is required",
            });
        }
        return isValidated;
    };
    const handleSave = async () => {
        if (handleValidations()) {
            if (formData.fieldType !== "date") {
                formData.formatType = null;
            }
            let fields = props.currentPage.fields;
            //temperory sending  validations=""
            let tmpFormData = formData;
            if (isAdd) {
                //Add new
                let newId = fields.length + 1;
                formData.id = newId;
                tmpFormData["isInverse"] =
                    tmpFormData["isInverse"] !== undefined
                        ? tmpFormData["isInverse"]
                        : false;
                fields.push(tmpFormData);
            } else {
                //edit
                for (let i = 0; i < fields.length; i++) {
                    if (fields[i].id == formData.id) {
                        fields[i] = tmpFormData;
                    }
                }
            }
            props.manageFields(fields);
            setTimeout(() => {
                setChangesSaved(true);
                props.onSave();
            }, 1000);
        }
    };
    const handleBack = () => {
        props.onSave();
    };
    const handleSnackbarClose = () => {
        setChangesSaved(false);
    };

    const classes = useStyles();
    return (
        <form className={classes.root} noValidate autoComplete="off">
            {props.fieldValidations && (
                <section className={classes.container}>
                    <Typography edge="start" variant="h6" color="primary">
                        {isAdd ? "Add new field" : `Editing | ${formData.name}`}
                    </Typography>
                    <br />
                    <Grid container spacing={0}>
                        <Grid item xs={8}>
                            <Grid container alignItems="flex-start">
                                <Grid item xs={3}>
                                    <Typography
                                        className={classes.label}
                                        variant="h6"
                                    >
                                        Name{" "}
                                        <span className="requiredMark">*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <A_Textbox
                                        variant="outlined"
                                        size="medium"
                                        id="name"
                                        fullWidth={true}
                                        defaultValue={formData.name}
                                        error={fieldNameValidation.error}
                                        helperText={fieldNameValidation.msg}
                                        onChange={handleFormData}
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="checkedC"
                                                color="primary"
                                                checked={!!formData.isPageTitle}
                                                onChange={handleTitleCheckbox}
                                            />
                                        }
                                        label="Is Title"
                                        className={classes.Checkbox}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                //name="checkedD"
                                                color="primary"
                                                checked={
                                                    !!formData.isSearchable
                                                }
                                                onChange={
                                                    handleSearchableCheckbox
                                                }
                                            />
                                        }
                                        label="Is Searchable"
                                        className={classes.Checkbox}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={!!formData.inNewTab}
                                                onChange={
                                                    handleCurrentTabCheckbox
                                                }
                                            />
                                        }
                                        label="open in new Tab"
                                        className={classes.Checkbox}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={!!formData.inLink}
                                                onChange={
                                                    handleLinkOpenCheckbox
                                                }
                                            />
                                        }
                                        label="link open"
                                        className={classes.Checkbox}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Typography
                                        className={classes.label}
                                        variant="h6"
                                    >
                                        Description
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <A_Textbox
                                        variant="outlined"
                                        size="medium"
                                        id="description"
                                        fullWidth={true}
                                        helperText=""
                                        multiline={true}
                                        rows={3}
                                        defaultValue={formData.description}
                                        onChange={handleFormData}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography
                                        className={classes.label}
                                        variant="h6"
                                    >
                                        Type{" "}
                                        <span className="requiredMark">*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <A_Select
                                        required={true}
                                        defaultValue={formData.fieldType}
                                        id="fieldType"
                                        helperText={fieldTypeValidation.msg}
                                        error={fieldTypeValidation.error}
                                        options={fieldTypes}
                                        onChange={handleDropdowns}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography
                                        className={classes.label}
                                        variant="h6"
                                    >
                                        Format
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <A_Select
                                        defaultValue={formData.formatType}
                                        id="formatType"
                                        onChange={handleDropdowns}
                                        disabled={formatDate}
                                        options={formatTypes}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                disabled={formatDate}
                                                checked={
                                                    !!formData.lettersAllowed
                                                }
                                                onChange={handlelettersAllowed}
                                            />
                                        }
                                        label="LettersAllowed"
                                        className={classes.Checkbox}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography
                                        className={classes.label}
                                        variant="h6"
                                    >
                                        Validations
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <A_Select
                                        required={true}
                                        defaultValue={formData.validations}
                                        id="validations"
                                        helperText=""
                                        error={false}
                                        options={validations}
                                        onChange={handleDropdowns}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Typography
                                        className={classes.label}
                                        variant="h6"
                                    >
                                        Align{" "}
                                        {!alignDisabled && (
                                            <span className="requiredMark">
                                                *
                                            </span>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <A_Select
                                        required={true}
                                        defaultValue={formData.align}
                                        id="align"
                                        helperText={alignValidation.msg}
                                        error={alignValidation.error}
                                        options={radioFields}
                                        onChange={handleDropdowns}
                                        disabled={alignDisabled}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Typography
                                        className={classes.label}
                                        variant="h6"
                                    >
                                        Edge Label{" "}
                                        {!destinationVerticesDisabled && (
                                            <span className="requiredMark">
                                                *
                                            </span>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <A_Textbox
                                        variant="outlined"
                                        size="medium"
                                        id="edgeLabel"
                                        fullWidth={true}
                                        defaultValue={formData.edgeLabel}
                                        helperText={edgeLabelValidation.msg}
                                        error={edgeLabelValidation.error}
                                        onChange={handleFormData}
                                        disabled={destinationVerticesDisabled}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography
                                        className={classes.label}
                                        variant="h6"
                                    >
                                        Destination Vertex Label{" "}
                                        {!destinationVerticesDisabled && (
                                            <span className="requiredMark">
                                                *
                                            </span>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <A_Select
                                        required={true}
                                        defaultValue={destinationVertexfield}
                                        id="destinationVertex"
                                        helperText={
                                            destinationVertexValidation.msg
                                        }
                                        error={
                                            destinationVertexValidation.error
                                        }
                                        options={destinationVertices}
                                        onChange={handleDropdowns}
                                        disabled={destinationVerticesDisabled}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography
                                        className={classes.label}
                                        variant="h6"
                                    >
                                        IsInverse{" "}
                                        {!destinationVerticesDisabled && (
                                            <span className="requiredMark">
                                                *
                                            </span>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <A_Select
                                        required={true}
                                        defaultValue={
                                            formData.isInverse
                                                ? formData.isInverse
                                                : false
                                        }
                                        id="isInverse"
                                        helperText={isInverseValidation.msg}
                                        error={isInverseValidation.error}
                                        options={[
                                            { label: "Yes", value: true },
                                            { label: "No", value: false },
                                        ]}
                                        onChange={handleDropdowns}
                                        disabled={destinationVerticesDisabled}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography
                                        className={classes.label}
                                        variant="h6"
                                    >
                                        Optionset{" "}
                                        {!optionSetDisabled && (
                                            <span className="requiredMark">
                                                *
                                            </span>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <A_Select
                                        required={true}
                                        defaultValue={formData.optionSet}
                                        id="optionSet"
                                        helperText={optionSetValidation.msg}
                                        error={optionSetValidation.error}
                                        options={optionSets}
                                        onChange={handleDropdowns}
                                        disabled={optionSetDisabled}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Typography
                                        className={classes.label}
                                        variant="h6"
                                    >
                                        Result Template{" "}
                                        {!resultTemplateDisabled && (
                                            <span className="requiredMark">
                                                *
                                            </span>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <A_Select
                                        required={true}
                                        defaultValue={formData.resultTemplate}
                                        helperText={
                                            resultTemplateValidation.msg
                                        }
                                        error={resultTemplateValidation.error}
                                        id="resultTemplate"
                                        options={resultTemplate}
                                        onChange={handleDropdowns}
                                        disabled={resultTemplateDisabled}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <A_Button
                                color="primary"
                                size="medium"
                                label={isAdd ? "Add field" : `Update`}
                                onClick={handleSave}
                            />
                            &nbsp;&nbsp;
                            <A_Button
                                color="default"
                                size="medium"
                                label="Back"
                                onClick={handleBack}
                            />
                        </Grid>
                    </Grid>
                    <A_Snackbar
                        open={changesSaved}
                        message="Changes saved successfully"
                        autoHideDuration={5000}
                        horizontal="center"
                        handleClose={handleSnackbarClose}
                    />
                </section>
            )}
            {!props.fieldValidations && <p>Loading form</p>}
        </form>
    );
};
function mapStateToProps(state) {
    return {
        fieldValidations: state.fieldValidations.data,
        destinationVertices: state.destinationVertices.data,
        resultTemplate: state.resultTemplate.data,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(currentPageActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageFieldsForm);
