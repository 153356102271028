import { take, put, select } from "redux-saga/effects";
import axios from "axios";
import { pageEditorActionTypes } from "../actions/actionTypes";
import * as pageEditorActions from "../actions/pageEditorActions";
import * as commonFunctions from "../../Utils/Helpers/common";

export function* getPagesSaga() {
    while (true) {
        const action = yield take(pageEditorActionTypes.GET_PAGES);
        const payload = action.payload;
        let config = commonFunctions.getHttpConfig();
        try {
            let newUrl = process.env.API_URL
            const res = yield axios.get(
                `${newUrl}/${payload}/PageConfiguration`,
                config
            );
            console.log("getPagesSaga response:", res);
            yield put(
                pageEditorActions.getPagesSuccessed({
                    data: res.data,

                    currentTenant: payload,
                    getPagesStarted: false,
                    fetchPagesFailed: false,
                })
            );
        } catch (e) {
            pageEditorActions.getPagesFailed({
                msg: "Some error occured",
                getPagesStarted: false,
                fetchPagesFailed: true,
            });
            console.log("can not fetch page configurations.");
            console.log(e.message);
        }
    }
}
export function* getAccessGroupsSaga() {
    while (true) {
        const action = yield take(pageEditorActionTypes.GET_ACCESS_GROUPS);
        const payload = action.payload;
        let config = commonFunctions.getHttpConfig();
        try {
            const res = yield axios.get(
                `${process.env.API_URL}/${payload}/PageTemplate/getGroups`,
                config
            );
            yield put(
                pageEditorActions.getAccessGroupsSucceeded({
                    data: res.data,
                    currentTenant: payload,
                    getAccessGroupsStarted: false,
                    getAccessGroupsFailed: false,
                })
            );
        } catch (e) {
            pageEditorActions.getAccessGroupsFailed({
                msg: "Error while fetching access groups",
                getAccessGroupsStarted: false,
                getAccessGroupsFailed: true,
            });
        }
    }
}
