import {chartEditorActionTypes} from "../actions/actionTypes";

let initialState = [];
const chartEditorReducer = (state = initialState,action) => {

    switch(action.type){
      case chartEditorActionTypes.GET_CHARTS:        
        return {getChartStarted: true};
      case chartEditorActionTypes.GET_CHARTS_SUCCESSED:        
        return action.payload;
      case chartEditorActionTypes.GET_CHARTS_FAILED:          
        return action.payload;  
        case chartEditorActionTypes.DELETE_CHARTS:        
        return {deleteChartStarted: true};
      case chartEditorActionTypes.DELETE_CHARTS_SUCCESSED:        
        return action.payload;
      case chartEditorActionTypes.DELETE_CHARTS_FAILED:          
        return action.payload;    
      default:
        return state;
    }
  };
  export default chartEditorReducer;