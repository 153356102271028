import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import A_Select from "../../../Atoms/Inputs/Selects/A_Select";
import { NativeSelect, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dropdownMenu: {
    background: "white",
    height: "40px",
    padding:"10px",
    [theme.breakpoints.down("sm")]: {
      height: "20px",

    },
    width: "250px",
  },
  dropContent: {
    display: " flex",

    columnGap: "21px",
  },
  text: {
    marginTop: "5px",
    fontSize: "22px",
    fontWeight:"500",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    
    },
  },
}));

const M_Dropdown = (props) => {
  const classes = useStyles();
  const [selectedValue ,setSelectedValue] = React.useState(props.value)
  


  const changeChandle =(event)=>{
    const name = event.target.value;
    setSelectedValue(name)
    props.changeChandle(name)

  }

  return (
    <div className={classes.dropContent}>
      <Typography edge="end" className={classes.text}>
        Client
      </Typography>
      <NativeSelect id={props.id} value={selectedValue} disabled={props.isdisabled} className={classes.dropdownMenu} onChange={(e)=>changeChandle(e)}>
        {props.data.map((item, index) => (
          <option key={item.value} id={item.value} value={item.value}>{item.label}</option>
        ))}
      </NativeSelect>
    </div>
  );
};
export default M_Dropdown;

M_Dropdown.defaultProps = {
  data: [
    {
      value: "",
      label: "",
    }
    
  ],
};
