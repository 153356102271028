import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Draggable from "react-draggable";
import Paper from "@material-ui/core/Paper";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Slide, Zoom, Fade, Grow, Collapse } from "@material-ui/core";
import theme from "../../../../Themes/defaultTheme";
import A_IconButton from "../../Inputs/Buttons/A_IconButton";

const A_SimpleDialog = (props) => {
  const useStyles = makeStyles((theme) => ({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }));
  const classes = useStyles();
  const handleOnClose = (value) => {
    if (props.onClose) {
      props.onClose();
    }
  };
  const PaperComponent = (props) => {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="A_DialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  };
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        showclosebutton={props.showclosebutton}
        onClose={handleOnClose}
        onExit={props.onExit}
        aria-labelledby="simple-dialog-title"
        open={props.open}
        TransitionComponent={Zoom}
        fullScreen={props.fullScreen}
        fullWidth={props.fullWidth}
        maxWidth={props.maxWidth}
        PaperComponent={PaperComponent}
        disableBackdropClick={props.disableBackdropClick}
        disableEscapeKeyDown={props.disableEscapeKeyDown}
      >
        <DialogTitle id="simple-dialog-title">
          {props.title}
          {props.showclosebutton == "true" ? (
            <A_IconButton
              icon={<CloseIcon />}
              aria-label="close"
              className={classes.closeButton}
              onClick={handleOnClose}
            ></A_IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.dialogContent}
          </DialogContentText>
        </DialogContent>

        <DialogActions>{props.dialogActions}</DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};
A_SimpleDialog.defaultProps = {
  open: false,
  title: "",
  height: "",
  dialogContent: "Dialog content Placeholder",
  fullScreen: false,
  fullWidth: true,
  maxWidth: "xs",
  showclosebutton: "true",
  disableBackdropClick: true,
  disableEscapeKeyDown: false,
  //transition: <Slide />,
};
export default A_SimpleDialog;
