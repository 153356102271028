import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import * as currentPageActions from "../../../../../../store/actions/currentPageActions";
import { useHistory } from "react-router-dom";
import Header from "../../MainPage/Header/Header";
import M_SideBar from "../../../../Molecules/Common/SideBar/M_SideBar";

import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import DisplayTemplates from "../Templates/DisplayTemplates/DisplayTemplates";
import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={"div"} variant={"body2"}>
                        {children}
                    </Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        height: "85vh",
    },
    tabs: {},
    tabPanel: {
        width: "800",
        border: "1px solid red",
    },
    BackButton: {
        marginLeft: "17px",
        marginTop: "20px",
    },
}));
const EditorMainPage = (props) => {
    const classes = useStyles();

    const [defaultTenant, setDefaultTenant] = React.useState(
        props.pages.currentTenant
    );
    const [value, setValue] = React.useState(0);
    const [showTemplatesTab, setShowTemplatesTab] = React.useState(true);
    const [currentPage, setCurrentPage] = React.useState(props.currentPage);
    const history = useHistory();

    let id = props.match.params.id == 0 ? null : props.match.params.id;
    let tenantId = props.pages.currentTenant;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleBack = () => {
        history.push("/pageeditor");
    };

    return (
        <div>
            <div>
                <Header
                    id="toolbar"
                    title="Pages editor"
                    TenantData={props.tenants}
                    value={defaultTenant}
                    isdisabledFlag={true}
                />
            </div>

            <div className={classes.root}>
                <div>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        className={classes.tabs}
                    >
                        <Tab label="Page Details" {...a11yProps(0)} />
                        <Tab
                            label="Templates"
                            {...a11yProps(1)}
                            disabled={
                                props.currentPage.id == null ? true : false
                            }
                        />
                    </Tabs>
                    <A_Button
                        className={classes.BackButton}
                        color="default"
                        size="medium"
                        label="Back to Pages"
                        onClick={handleBack}
                    />
                </div>
                <TabPanel value={value} index={0}>
                    <M_SideBar
                        currentPage={currentPage}
                        tenantId={tenantId}
                        id={id}
                        onSave={setShowTemplatesTab}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <DisplayTemplates tenantId={tenantId} id={id} />
                </TabPanel>
            </div>
        </div>
    );
};
function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        tenants: state.tenants.data,
        pages: state.pages,
        currentPage: state.currentPage,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(currentPageActions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(EditorMainPage);
