import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import M_AceEditor from "../../../../../../Molecules/Common/AceEditor/M_AceEditor";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const useStyles = makeStyles((theme) => ({
    editor: {
        height: "100px",
    },
}));

const ChartDataPreview = (props) => {
    const classes = useStyles();
    const [inputData, setInputData] = React.useState(
        props.PreviewData && props.PreviewData.data
            ? JSON.stringify(props.PreviewData.data)
            : "{}"
    );
    return (
        <div>
            <M_AceEditor
                mode="text"
                width={"100%"}
                height={"40vh"}
                value={inputData}
            />
        </div>
    );
};

function mapStateToProps(state) {
    return {
        tenants: state.tenants.data,
        PreviewData: state.PreviewData,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(Object.assign({}), dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartDataPreview);
