import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
import theme from "../../../../themes/defaultTheme";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function sectionHeader(data) {
    const sectionHeadObj = {};

    const withHeader = data.filter((item) => {
        if (item.sectionHeader != null) {
            sectionHeadObj[item.sectionHeader] = true;
            return true;
        }
    });
    const sectionArr = [];
    const withoutHeader = data.filter((item) => item.sectionHeader == null);
    for (const key in sectionHeadObj) {
        const sectionHeadArr = withHeader.filter(
            (item) => item.sectionHeader == key
        );
        if (sectionHeadArr[0]) {
            sectionHeadArr[0]["isHeader"] = true;
        }
        sectionArr.push(...sectionHeadArr);
    }

    return [...withoutHeader, ...sectionArr];
}

function EnhancedTableHead(props) {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        col,
        action,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {col &&
                    Object.keys(col).map(
                        (headCell) =>
                            !col[headCell].hide &&
                            (col[headCell].sorting ? (
                                <TableCell
                                    key={col[headCell].id}
                                    sortDirection={
                                        orderBy === col[headCell].id
                                            ? order
                                            : false
                                    }
                                    width="70%"
                                >
                                    <TableSortLabel
                                        active={orderBy === col[headCell].id}
                                        direction={
                                            orderBy === col[headCell].id
                                                ? order
                                                : "asc"
                                        }
                                        onClick={createSortHandler(
                                            col[headCell].id
                                        )}
                                    >
                                        {col[headCell].label}
                                        {orderBy === col[headCell].id ? (
                                            <span
                                                className={
                                                    classes.visuallyHidden
                                                }
                                            >
                                                {order === "desc"
                                                    ? "sorted descending"
                                                    : "sorted ascending"}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                            ) : (
                                <TableCell key={col[headCell].id} width="20%">
                                    {col[headCell].label}
                                </TableCell>
                            ))
                    )}
                {action && (action.edit || action.delete) && (
                    <TableCell width="10%"></TableCell>
                )}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    col: PropTypes.object.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === "light"
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    title: {
        flex: "1 1 100%",
        marginTop: "30px",
        marginBottom: "20px",
    },
    searchBox: {
        marginTop: "30px",
        marginBottom: "20px",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    addButton: {
        marginRight: "0px",
        width: "30px",
        height: "30px",
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;
    const { title } = props.config;
    const { searchable } = props.config;
    const { addButton } = props.config;
    const { data } = props;

    const handleChangeSearch = (event) => {
        // return results
        props.onChange(event, data);
    };

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {title}
                </Typography>
            )}
            {searchable ? (
                <A_TextField
                    className={classes.searchBox}
                    onChange={handleChangeSearch}
                    label="Search"
                    adornment={{ position: "start", value: <SearchIcon /> }}
                />
            ) : (
                ""
            )}
            {addButton ? (
                <AddIcon
                    id="addButton"
                    className={classes.addButton}
                    onClick={props.handleAddButtonClick}
                />
            ) : (
                ""
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    config: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: "750",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    tableContainer: {
        maxHeight: "40vh",
    },
    rowStyle: {
        background: "#67B7D1",
        borderBottom: "solid #318CE7 ",
    },
    cellStyle: {
        fontWeight: "bold",
    },
    cellStyle1: {
        background: "#67B7D1",
    },
}));

const A_EnhancedTable = (props) => {
    const classes = useStyles();
    const { columnConfig } = props.configurations;
    const { configurations } = props;
    const dataOrignal = props.data;
    const { action } = props.configurations;
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [data, setData] = React.useState(props.data);

    React.useEffect(() => {
        setData(dataOrignal);
    }, [dataOrignal]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const handleSearchChange = (event) => {
        let valuSearch = event.target.value;

        if (!valuSearch || valuSearch == "") {
            setData(dataOrignal);
            return;
        }
        let results = [];
        for (var i = 0; i < dataOrignal.length; i++) {
            for (let key in dataOrignal[i]) {
                if (
                    typeof dataOrignal[i][key] == "string" &&
                    dataOrignal[i][key]
                        .toLowerCase()
                        .indexOf(valuSearch.toLowerCase()) != -1
                ) {
                    results.push(dataOrignal[i]);
                    break;
                }
            }
        }
        setData(results);
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        config={configurations}
                        data={data}
                        onChange={handleSearchChange}
                        handleAddButtonClick={props.handleAddButtonClick}
                    />
                    <TableContainer
                        className={clsx({
                            [classes.tableContainer]:
                                configurations.isMaxHeight,
                        })}
                    >
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={dense ? "small" : "medium"}
                            aria-label="enhanced 
                            table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={data.length}
                                col={columnConfig}
                                action={action}
                            />
                            <TableBody>
                                <>
                                    {data && data.length > 0 ? (
                                        sectionHeader(
                                            stableSort(
                                                data,
                                                getComparator(order, orderBy)
                                            )
                                        )
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row, index) => {
                                                const isItemSelected =
                                                    isSelected(data.name);

                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                // Table column

                                                return (
                                                    <>
                                                        {row.sectionHeader !=
                                                            null &&
                                                        row.isHeader ? (
                                                            <TableRow
                                                                className={
                                                                    classes.rowStyle
                                                                }
                                                            >
                                                                <TableCell
                                                                    className={
                                                                        classes.cellStyle
                                                                    }
                                                                >
                                                                    {
                                                                        row.sectionHeader
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    className={
                                                                        classes.cellStyle1
                                                                    }
                                                                />
                                                                <TableCell
                                                                    className={
                                                                        classes.cellStyle1
                                                                    }
                                                                />
                                                                <TableCell
                                                                    className={
                                                                        classes.cellStyle1
                                                                    }
                                                                />
                                                            </TableRow>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <TableRow key={index}>
                                                            {row
                                                                ? Object.keys(
                                                                      row
                                                                  ).map((key) =>
                                                                      configurations &&
                                                                      configurations.columnConfig &&
                                                                      configurations
                                                                          .columnConfig[
                                                                          key
                                                                      ] &&
                                                                      !configurations
                                                                          .columnConfig[
                                                                          key
                                                                      ].hide ? (
                                                                          <TableCell
                                                                              key={
                                                                                  key
                                                                              }
                                                                          >
                                                                              {configurations
                                                                                  .columnConfig[
                                                                                  key
                                                                              ]
                                                                                  .link ? (
                                                                                  <a
                                                                                      href={
                                                                                          row[
                                                                                              configurations
                                                                                                  .columnConfig[
                                                                                                  key
                                                                                              ]
                                                                                                  .linkName
                                                                                          ]
                                                                                      }
                                                                                  >
                                                                                      {
                                                                                          row[
                                                                                              key
                                                                                          ]
                                                                                      }{" "}
                                                                                  </a>
                                                                              ) : Array.isArray(
                                                                                    row[
                                                                                        key
                                                                                    ]
                                                                                ) ? (
                                                                                  row[
                                                                                      key
                                                                                  ].map(
                                                                                      (
                                                                                          item
                                                                                      ) => {
                                                                                          //nested Table column
                                                                                          return (
                                                                                              <>
                                                                                                  <TableRow
                                                                                                      key={
                                                                                                          index
                                                                                                      }
                                                                                                  >
                                                                                                      <TableCell>
                                                                                                          {
                                                                                                              item.label
                                                                                                          }
                                                                                                      </TableCell>
                                                                                                      {props.optionSetMultipleValues && (
                                                                                                          <TableCell>
                                                                                                              {
                                                                                                                  item.value
                                                                                                              }
                                                                                                          </TableCell>
                                                                                                      )}

                                                                                                      <br></br>
                                                                                                  </TableRow>
                                                                                              </>
                                                                                          );
                                                                                      }
                                                                                  )
                                                                              ) : (
                                                                                  row[
                                                                                      key
                                                                                  ]
                                                                              )}
                                                                          </TableCell>
                                                                      ) : (
                                                                          ""
                                                                      )
                                                                  )
                                                                : ""}

                                                            {action ? (
                                                                <TableCell align="left">
                                                                    {action.edit ? (
                                                                        <EditIcon
                                                                            //Due to duplicate occurence of edit icon in Tenant.jsx file after changing Name of Key parameter it is working perfectly line so i am commmenting a code for now
                                                                            // key={
                                                                            //     row.id
                                                                            // }

                                                                            id={
                                                                                row.id
                                                                            }
                                                                            onClick={
                                                                                props.handleEditButtonClick
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {action.delete ? (
                                                                        <DeleteIcon
                                                                            key={
                                                                                index
                                                                            }
                                                                            id={
                                                                                index
                                                                            }
                                                                            dataid={
                                                                                row.id
                                                                            }
                                                                            onClick={
                                                                                props.handleDeleteButtonClick
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {action.reset ? (
                                                                        <RotateLeftIcon
                                                                            key={
                                                                                row.id
                                                                            }
                                                                            id={
                                                                                row.id
                                                                            }
                                                                            dataid={
                                                                                row.id
                                                                            }
                                                                            onClick={
                                                                                props.handleResetButtonClick
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </TableCell>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </TableRow>
                                                    </>
                                                );
                                            })
                                    ) : (
                                        <TableRow
                                            style={{
                                                height:
                                                    (dense ? 33 : 53) *
                                                    emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6}>
                                                {configurations.noDataText}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {configurations && configurations.paginate ? (
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    ) : (
                        ""
                    )}
                </Paper>
            </div>
        </ThemeProvider>
    );
};
A_EnhancedTable.defaultProps = {
    configurations: {
        title: "Heading",
        noDataText: "No data available.",
        paginate: true,
        searchable: true,
        sortable: true,
        striped: true,
        columnConfig: {
            uname: {
                id: "uname",
                label: "User Name",
                sorting: false,
                hide: false,
            },
            ContactNo: {
                id: "ContactNo",
                label: "Contact No",
                sorting: true,
                hide: false,
            },
            email: {
                id: "email",
                label: "Email",
                sorting: true,
                hide: false,
            },
        },
    },

    data: [
        // {
        //     uname: "user 1",
        //     ContactNo: "9927927429",
        //     email: "vser1@gmail.com",
        // },
        // {
        //     uname: "user 2",
        //     ContactNo: "3342792742",
        //     email: "user2@gmail.com",
        // },
    ],
};

export default A_EnhancedTable;
