import axios from "axios";
import { getHttpConfig } from "../Helpers/common";

const baseUrl = process.env.API_URL;

const httpConfig = getHttpConfig();
if (
    !httpConfig.headers.Authorization ||
    httpConfig.headers.Authorization == "Bearer undefined"
) {
    const serializedState = sessionStorage.getItem(
        `oidc.user:${process.env.OIDC_AUTHORITY}:${process.env.CLIENT_ID}`
    );
    const appState = JSON.parse(serializedState);
    if (appState && appState.access_token) {
        httpConfig.headers.Authorization = "Bearer " + appState.access_token;
    }
}

//http get calls handler
export const httpGet = async (url, params, config = httpConfig) => {
    let finalUrl = url.indexOf("http") > -1 ? url : `${baseUrl}${url}`;
    const result = await axios
        .get(finalUrl, params, config)
        .then((response) => response.data)
        .catch((error) => error);
    return result;
};

//http post calls handler
export const httpPost = async (url, data, config) => {
    const result = await axios
        .post(url, data, config)
        .then((response) => response.data)
        .catch((error) => error);
    return result;
};

//http put calls handler
export const httpPut = async (url, data, config) => {
    const result = await axios
        .put(url, data, config)
        .then((response) => response.data)
        .catch((error) => error);
    return result;
};

//http delete calls handler
export const httpDelete = async (url, data, config) => {
    const result = await axios
        .delete(url, data, config)
        .then((response) => response.data)
        .catch((error) => error);
    return result;
};
