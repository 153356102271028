export const templateType = {
    FORM: "form",
    VIEW: "view",
};
export const templateGroupType = {
    STRING: "string",
};
export const messages = {
    CONFIRMATION_MSG: "Once option set will create it can not be modified",
};
