 export const templateTypes = [
     {
         id: "view",
         label: "view",
         value: "view",
     },

     {
         id: "form",
         label: "form",
         value: "form",
     },
     {
         id: "result",
         label: "result",
         value: "result",
     },
     {
         id: "customController",
         label: "custom controller",
         value: "customController",
     },
 ];